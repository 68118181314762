import { put, takeLatest, select } from "redux-saga/effects";
import {
  ORDER_PLACE_DETAIL,
  ORDER_PLACE_DETAIL_RECEIVED,
  EMPTY_ORDER_PLACE_DETAIL,
  CART_DETAIL_STATUS_CHANGED,
  EMPTY_CART_DETAIL,
  LOADING_STATUS,
  GET_ORDER_HISTORY_DETAIL,
  ORDER_HISTORY_DETAIL_RECEIVED,
  EMPTY_ORDER_HISTORY_DETAIL,
  DELETE_RECENT_AND_CART_ORDER,
  EMPTY_VOUCHER_LIST_DETAIL,
  GET_VOUCHER_LIST_DETAIL,
  VOUCHER_LIST_DETAIL_RECEIVED,
  EMPTY_RECENT_ORDER_ID,
  RECENT_ORDER_ID_RECEIVED,
} from "../actions/types";
import { showNotificationWithTimeout, changeOrderSocketEmit } from "../actions";

const defaultErrorMsg = "Something went wrong! try again later.";
const base_url = process?.env?.REACT_APP_API_URL;
let myHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
  // "Access-Control-Allow-Origin": "*",
};

function* orderPlace(action) {
  console.log("base_url", base_url);
  console.log("action.body", action?.body);
  yield put({ type: EMPTY_ORDER_PLACE_DETAIL });
  yield put({ type: EMPTY_RECENT_ORDER_ID });
  yield put({ type: LOADING_STATUS, status: true });
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  myHeaders.token = token;
  console.log("myHeaders", myHeaders);
  const json = yield fetch(`${base_url}/qr_code_apis/submitOrder`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify(action?.body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("orderPlace failed : " + error.message));
  if (json?.status && json?.data) {
    // yield put({ type: EMPTY_CART_DETAIL });
    yield put({ type: CART_DETAIL_STATUS_CHANGED });
    yield put({ type: ORDER_PLACE_DETAIL_RECEIVED, payload: json?.data });
    yield put({
      type: RECENT_ORDER_ID_RECEIVED,
      payload: json?.data?.["order_list"]?.[0]?.order_offline_id ?? "",
    });
    yield put(changeOrderSocketEmit(true));
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : "success"}`,
        "success"
      )
    );
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `orderPlace error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* orderHistory(action) {
  console.log("base_url", base_url);
  console.log("action.body", action?.body);
  yield put({ type: EMPTY_ORDER_HISTORY_DETAIL });
  yield put({ type: EMPTY_VOUCHER_LIST_DETAIL });
  yield put({ type: LOADING_STATUS, status: true });
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  const card_detail = yield select(
    (state) => state?.authDetailReducer?.card_detail
  );
  myHeaders.token = token;
  console.log("myHeaders", myHeaders);
  // const json = yield fetch(`${base_url}/qr_code_apis/get_loyalty_history`, {
  const json = yield fetch(`${base_url}/ascentis/get_transaction_history`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify({
      CardNo: action?.card ?? "",
      merchant_id: action?.merchantId ?? "",
      deviceid: action?.deviceId ?? "",
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("ORDER_HISTORY failed : " + error.message));
  if (json?.status && json?.data) {
    // yield put({ type: EMPTY_CART_DETAIL });
    yield put({ type: ORDER_HISTORY_DETAIL_RECEIVED, payload: json?.data });
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `ORDER_HISTORY error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  if (card_detail?.CardInfo?.CardNo) {
    const voucherList = yield fetch(`${base_url}/ascentis/get_vouchers`, {
      method: "POST",
      mode: "cors",
      headers: new Headers(myHeaders),
      body: JSON.stringify({
        CardNo: card_detail?.CardInfo?.CardNo,
      }),
    })
      .then((response) => response.json())
      .then((json) => json)
      .catch((error) => console.log("VOUCHER_LIST failed : " + error.message));
    if (voucherList?.status && voucherList?.data) {
      // yield put({ type: EMPTY_CART_DETAIL });
      yield put({
        type: VOUCHER_LIST_DETAIL_RECEIVED,
        payload: voucherList?.data,
      });
    } else {
      yield put(
        showNotificationWithTimeout(
          `${voucherList?.message ? voucherList.message : defaultErrorMsg}`,
          "error"
        )
      );
      console.log(
        `VOUCHER_LIST error : ${
          voucherList?.message ? voucherList.message : defaultErrorMsg
        }`
      );
    }
  }

  yield put({ type: LOADING_STATUS, status: false });
}

function* deletRecentAndCartOrder(action) {
  yield put({ type: LOADING_STATUS, status: true });
  yield put({ type: EMPTY_CART_DETAIL });
  yield put({ type: EMPTY_ORDER_PLACE_DETAIL });
  yield put({ type: EMPTY_RECENT_ORDER_ID });
  yield put({ type: LOADING_STATUS, status: false });
  yield put(showNotificationWithTimeout("Order Completed!", "success"));
}

export default function* cartDetailActionWatcher() {
  yield takeLatest(ORDER_PLACE_DETAIL, orderPlace);
  yield takeLatest(GET_ORDER_HISTORY_DETAIL, orderHistory);
  yield takeLatest(DELETE_RECENT_AND_CART_ORDER, deletRecentAndCartOrder);
}
