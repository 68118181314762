import { put, takeLatest, select, call } from "redux-saga/effects";
import axios from "axios";
import {
  SEND_OTP_DETAIL,
  SEND_OTP_DETAIL_RECEIVED,
  EMPTY_OTP_DETAIL,
  EMPTY_CARD_DETAIL,
  LOADING_STATUS,
  GET_USER_DETAIL,
  USER_DETAIL_RECEIVED,
  OTP_INPUT_RECEIVED,
  SHOW_OTP_INPUT_TOGGLE,
  GET_LOYALITY_DETAIL,
  CARD_DETAIL_RECEIVED,
  CART_DETAIL_RECEIVED,
  EMPTY_RECENT_ORDER_ID,
  RECENT_ORDER_ID_RECEIVED,
} from "../actions/types";
import { showNotificationWithTimeout, getUserDetail } from "../actions";
import { apiCall, getCartSubmittedItems } from "../../helpers";

const defaultErrorMsg = "Something went wrong! try again later.";
const base_url = process?.env?.REACT_APP_API_URL;
let myHeaders = new Headers({
  "Content-Type": "application/json",
  Accept: "application/json",
  // "Access-Control-Allow-Origin": "*",
});
function* sendOtpSms(action) {
  console.log("base_url", base_url);
  console.log("check_input:", action);
  // TODO
  // const authReducer = yield select((state) => state.authDetailReducer);
  // console.log("authReducer in saga", authReducer);

  yield put({ type: EMPTY_OTP_DETAIL });
  yield put({ type: LOADING_STATUS, status: true });
  const json = yield fetch(`${base_url}/qr_code_apis/verifyOtp`, {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify({
      email: action?.email ?? "",
      otp: action?.otp ?? "",
      merchant_id: action?.merchantId ?? "",
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("sendOtpSms failed : " + error.message));
  if (json?.status) {
    if (action?.otp !== undefined && action?.otp !== "") {
      yield put(
        getUserDetail(
          action?.email ?? "",
          action?.merchantId ?? "",
          action?.deviceId ?? ""
        )
      );
    } else {
      yield put(
        showNotificationWithTimeout(
          `${json?.message ? json.message : "success"}`,
          "success"
        )
      );
      yield put({ type: SHOW_OTP_INPUT_TOGGLE, input: true });
      yield put({ type: OTP_INPUT_RECEIVED, input: "" });
    }
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `sendOtpSms error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* loginUser(action) {
  console.log("base_url", base_url);
  yield put({ type: EMPTY_OTP_DETAIL });
  yield put({ type: EMPTY_RECENT_ORDER_ID });
  yield put({ type: LOADING_STATUS, status: true });
  const json = yield fetch(`${base_url}/qr_code_apis/login`, {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify({
      merchant_id: action?.merchantId ?? "",
      email: action?.email ?? "",
      deviceid: action?.deviceId ?? "",
      table_number: action?.tableNumber ?? "",
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("loginUser failed : " + error.message));
  if (json?.status && json?.data) {
    if (json?.data?.orders !== null) {
      const orders = json?.data?.orders;
      const table_detail = yield select(
        (state) => state?.tableDetailReducer?.table_detail
      );
      yield put({
        type: RECENT_ORDER_ID_RECEIVED,
        payload: orders?.order_offline_id ?? "",
      });
      // console.log("ordersssss===>", orders);
      const items = getCartSubmittedItems(orders, table_detail);
      // console.log("ordersssss===>items", items);
      // debugger;
      if (items?.length > 0) {
        yield put({ type: CART_DETAIL_RECEIVED, cart_detail: items });
      }
    }
    yield put({ type: USER_DETAIL_RECEIVED, payload: json?.data });
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : "success"}`,
        "success"
      )
    );
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `loginUser error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

// loyality API

const loyality_api_url =
  "https://uatcrm.ascentis.com.sg/APIsWrapperIdentity/connect/token";
const loyality_instance_url =
  "https://uatcrm.ascentis.com.sg/APIsWrapperGateway/apiv1";
const loyality_grant_type = "client_credentials";
const loyality_scope = "crmapi";
const loyality_client_id = "HarrysBar_POS-GoGMGo\\testuser";
const loyality_client_secret = "qUDVNPN3MgFp3u7R";
let loyalityHeaders = new Headers({
  // "Content-Type": "application/x-www-form-urlencoded",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
});

function* loyalityCardCheck(action) {
  yield put({ type: LOADING_STATUS, status: true });
  yield put({ type: EMPTY_CARD_DETAIL });
  const cardDetails = yield fetch(`${base_url}/ascentis/card_enquiry`, {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify({
      CardNo: action?.card ?? "",
      // CVC: "",
      CVC: action?.cvv ?? "",
      merchant_id: action?.merchantId ?? "",
      deviceid: action?.deviceId ?? "",
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("cardDetails failed : " + error.message));

  console.log("cardDetails", cardDetails);

  if (cardDetails?.status && cardDetails?.data) {
    console.log("cardDetails===>", cardDetails);
    // debugger;
    yield put({ type: CARD_DETAIL_RECEIVED, payload: cardDetails?.data });
    yield put(
      getUserDetail(
        "",
        action?.merchantId,
        action?.deviceId,
        action?.tableNumber
      )
    );
  } else {
    yield put(
      showNotificationWithTimeout(
        `${cardDetails?.message ? cardDetails.message : defaultErrorMsg}`,
        "error"
      )
    );
    console.log(
      `cardDetails error : ${
        cardDetails?.message ? cardDetails.message : defaultErrorMsg
      }`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

export default function* authDetailActionWatcher() {
  yield takeLatest(SEND_OTP_DETAIL, sendOtpSms);
  yield takeLatest(GET_USER_DETAIL, loginUser);
  yield takeLatest(GET_LOYALITY_DETAIL, loyalityCardCheck);
}
