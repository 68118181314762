import React from "react";
import { useHistory } from "react-router-dom";

function CartHeader({ backTitle, rtComponent, backPath, onTabProfile, style }) {
  const history = useHistory();
  return (
    <header style={style}>
      <div className="container">
        <div className="row">
          <div onClick={() => backPath()} className="col-6">
            <a href="#" className="back" onClick={() => backPath()}>
              {" "}
              {backTitle}{" "}
            </a>
          </div>
          <div className="col-6 notify">
            <a href="#" className="notification">
              {/* <span className="counts"> 10 </span> */}
            </a>
            <a href="#" onClick={() => onTabProfile()} className="profile">
              {" "}
            </a>
          </div>
        </div>
        {rtComponent ? rtComponent : null}
      </div>
    </header>
  );
}

export default CartHeader;
