import React, { useRef } from "react";
import { useState } from "react";
import Collapsible from "react-collapsible";
import {
  capitalizeFirstLetter,
  nFormatter,
  getUnixDateFormat,
} from "../../helpers";
import { voucherPngIcon } from "../../Assets";

function PromotionDetails({ voucher }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="promotion_details">
      <div className="row mx-0 justify-content-start">
        <div className="squre-img col-4 mx-0 px-0">
          <figure className="">
            <img src={voucherPngIcon} alt="" />
          </figure>
        </div>
        <div className="naming-sec col-8 pr-0">
          <h4>
            {" "}
            {voucher?.VoucherTypeName != ""
              ? voucher?.VoucherTypeName
              : "N/A"}{" "}
          </h4>
          <p>
            <em>
              {" "}
              Expiry Date:{" "}
              {voucher?.ValidTo
                ? getUnixDateFormat(voucher?.ValidTo, "DD/MM/YYYY")
                : "N/A"}{" "}
            </em>
          </p>
          <div className="btns d-flex pt-3">
            <button className="more-btn" onClick={() => setOpen(!open)}>
              {" "}
              More{" "}
            </button>
            <button className="green-btn btn-sm"> Apply </button>
          </div>
        </div>
      </div>
      <Collapsible open={open}>
        <div className="row">
          <div className="col-4 pr-0">
            <label> Promotion Code </label>
            <p> {voucher?.VoucherNo != "" ? voucher?.VoucherNo : "N/A"} </p>
          </div>
          <div className="col-4 pr-0">
            <label> Promotion Type </label>
            <p> {`${voucher?.Type == "Cash" ? "$" : "%"}`} Discount </p>
          </div>
          <div className="col-4 pr-0">
            <label> Amount </label>
            <p>
              {" "}
              {`${voucher?.Type == "Cash" ? "$" : "%"}`}
              {voucher?.TypeValue ? parseFloat(voucher?.TypeValue) : "N/A"}{" "}
            </p>
          </div>
          <div className="col-4 pr-0">
            <label> Qualifying Items </label>
            <p> Order </p>
          </div>
        </div>
      </Collapsible>
    </div>
  );
}

export default PromotionDetails;
