import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";

import { image2Icon, userImgIcon } from "../../Assets";
import OrderHistoryDetails from "../Components/OrderHistoryDetails";

function LoyalityHistory(params) {
  const orderDetail = useSelector(
    (state) => state?.cartDetailReducer?.order_history_detail
  );
  const recordNotFound = (
    <div className="history_details text-center">
      <p>no records found</p>
    </div>
  );
  return (
    <section className="info-sec">
      <div className="history-info" id="accordionExample">
        <h4 className="title"> History </h4>
        {orderDetail !== null &&
          orderDetail?.TransactionLists?.length > 0 &&
          orderDetail?.TransactionLists?.filter(
            (p) => p?.TransactType != "SALES"
          ).map((order, index) => {
            return <OrderHistoryDetails order={order} key={index} />;
          })}
        {(orderDetail == undefined ||
          orderDetail == null ||
          (orderDetail?.TransactionLists !== undefined &&
            orderDetail?.TransactionLists?.filter(
              (p) => p?.TransactType != "SALES"
            ).length === 0)) &&
          recordNotFound}
      </div>
    </section>
  );
}
export default LoyalityHistory;
