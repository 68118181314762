import React, { useEffect, useState, useRef, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { DeviceUUID } from "device-uuid";
import {
  cameraIcon,
  imagePlaceholderIcon,
  qrCodeIcon,
  userImgIcon,
} from "../../Assets";
import QrInput from "../Components/QR_code/QrInput";
import QrCamera from "../Components/QR_code/QrCamera";
import ImageTag from "../Components/ImageTag";
import {
  updateEmailInput,
  sendOtpDetail,
  getUserDetail,
  updateOtpInput,
  showNotificationWithTimeout,
  getLoyalityDetail,
} from "../../Redux/actions";
import { connect, useSelector, useDispatch } from "react-redux";
import Modal from "../Components/Modal";

let Login = ({}) => {
  const [scannerModal, setScannerModal] = useState(false);
  const qrInputRef = useRef("qrInputRef");
  const qrCameraRef = useRef("qrCameraRef");

  var uuid = new DeviceUUID().get();
  let history = useHistory();
  const authReducer = useSelector((state) => state.authDetailReducer);
  const loaderReducer = useSelector((state) => state.loaderReducer);
  const tableReducer = useSelector((state) => state.tableDetailReducer);

  const email = authReducer.email_input;
  const otp = authReducer.otp_input;
  const merchantId = tableReducer?.table_detail?.Merchant?.id;
  const tableNumber =
    tableReducer?.table_detail?.PosTableMaster?.table_number ?? "";
  const deviceId = uuid;
  const dispatch = useDispatch();
  console.log("authReducer:", authReducer);
  console.log("tableReducer:", tableReducer);
  console.log("loaderReducer:", loaderReducer);

  // redirect / if not have table detail
  if (
    loaderReducer?.loading === false &&
    tableReducer?.table_detail?.show_table_not_found_modal === false &&
    (tableReducer?.table_detail?.Merchant?.id === "" ||
      tableReducer?.table_detail?.Merchant?.id === null ||
      tableReducer?.table_detail?.Merchant?.id === undefined ||
      tableReducer?.table_detail?.PosTableMaster?.qr_code_id === "" ||
      tableReducer?.table_detail?.PosTableMaster?.qr_code_id === null ||
      tableReducer?.table_detail?.PosTableMaster?.qr_code_id === undefined)
  ) {
    history.push("/404");
  }
  // if already login send to menu page
  if (
    tableReducer?.table_detail?.PosTableMaster?.qr_code_id !== undefined &&
    loaderReducer?.loading === false &&
    authReducer?.user_detail !== null &&
    authReducer?.user_detail !== undefined
  ) {
    history.push(
      `/get_table_details/${tableReducer?.table_detail?.PosTableMaster?.qr_code_id}`
    );
  }

  const emailSubmitHandler = (el) => {
    el.preventDefault();
    dispatch(sendOtpDetail(email, otp, merchantId, deviceId));
  };

  const resendSubmitHandler = (el) => {
    dispatch(sendOtpDetail(email, "", merchantId, deviceId));
  };

  const guestSubmitHandler = (el) => {
    dispatch(getUserDetail("", merchantId, deviceId, tableNumber));
  };

  const emailChangeHandler = (el) => {
    console.log("element:", el.target.value);
    dispatch(updateEmailInput(el.target.value));
  };

  const otpChangeHandler = (el) => {
    console.log("element:", el.target.value);
    dispatch(updateOtpInput(el.target.value));
  };

  return (
    <div className="wrapper">
      {/* <header className="verify_header">
        <div className="container">
          <div className="row">
            <div className="col-8 left_col">
              <a href="#_" className="back">
                {" "}
                Back to Order{" "}
              </a>
            </div>
            <div className="col-4 notify">
              <a href="#_" className="notification">
                <span className="counts"> 10 </span>
              </a>
              <a href="#_" className="profile">
                {" "}
              </a>
            </div>
          </div>
        </div>
      </header> */}
      {/* <div className="overlay"> </div> */}
      <section className="varification-sec">
        {/* <a href="#_" className="cross-icon"></a> */}
        <div className="circle-img col-2">
          <figure className="">
            <ImageTag src={tableReducer?.table_detail?.Merchant?.logo} alt="" />
          </figure>
        </div>

        <div className="login-form pt-4 px_15">
          <form onSubmit={emailSubmitHandler}>
            <h5 className="title"> Device Verfication </h5>
            <div className="title-heading">
              {<span className="num"> 1 </span>}
              Login by Confirming Via Email OTP{" "}
            </div>
            <div className="row mx-0 justify-content-end">
              <input
                type="text"
                className="input-text"
                placeholder="Email Address"
                value={authReducer?.email_input ?? ""}
                onChange={emailChangeHandler}
              />
              {authReducer?.show_otp_input === true && (
                <input
                  type="text"
                  className="input-text mt-0 mb-2"
                  placeholder="Enter OTP"
                  value={authReducer?.otp_input ?? ""}
                  onChange={otpChangeHandler}
                />
              )}
            </div>

            <div className="resend_row">
              {authReducer?.show_otp_input === true && (
                <button
                  type="button"
                  className="btn green-btn"
                  onClick={resendSubmitHandler}
                >
                  Resend
                </button>
              )}
              <button type="submit" className="btn green-btn">
                {" "}
                Submit{" "}
              </button>
            </div>
          </form>

          <div className="or">
            {" "}
            <span> Or </span>{" "}
          </div>

          <div className="title-heading">
            {<span className="num"> 2 </span>}
            Member App QR Code Scan{" "}
          </div>

          <div className="qr-sec">
            <figure className="m-0">
              <img src={qrCodeIcon} />
            </figure>

            <div className="btn-sec col-12 d-flex px-0 pb-0">
              <a
                href="javascript:void(0);"
                className="green-btn mr-2"
                onClick={() => {
                  document.body.classList.add("modal-open");
                  setScannerModal(true);
                }}
              >
                <div className="cam">
                  <img src={cameraIcon} />
                  <br /> Use Camera{" "}
                </div>
              </a>

              <a
                href="#_"
                className="green-btn ml-2"
                onClick={() => qrInputRef.current.click()}
              >
                <div className="d-none">
                  <QrInput
                    handleScan={(res) => {
                      console.log("qr_scan", res);
                      if (res) {
                        const scanArr = res.split("|");
                        if (scanArr.length > 2 && scanArr[0] == "QR") {
                          dispatch(
                            getLoyalityDetail(
                              scanArr[1],
                              scanArr[2],
                              merchantId,
                              deviceId,
                              tableNumber
                            )
                          );
                        } else {
                          dispatch(
                            showNotificationWithTimeout(
                              "Invalid QR code",
                              "error"
                            )
                          );
                        }
                      }
                    }}
                    ref={qrInputRef}
                    onError={(err) =>
                      dispatch(showNotificationWithTimeout(err, "error"))
                    }
                  />
                </div>
                <div className="img">
                  <img src={imagePlaceholderIcon} />
                  <br /> Use Image
                </div>
              </a>
            </div>
          </div>

          <div className="or">
            {" "}
            <span> Or </span>{" "}
          </div>

          <div className="guest_login">
            <a href="javascript:void(0)" onClick={guestSubmitHandler}>
              Continue as a guest
            </a>
          </div>
        </div>

        <section className={`qrcode_modal ${scannerModal ? "show" : ""}`}>
          <div className="top">
            <h4>Scan QR Code</h4>
          </div>
          {scannerModal && (
            <QrCamera
              class="qr-camera-container"
              handleScan={(res) => {
                if (res) {
                  const scanArr = res.split("|");
                  if (scanArr.length > 2 && scanArr[0] == "QR") {
                    dispatch(
                      getLoyalityDetail(
                        scanArr[1],
                        scanArr[2],
                        merchantId,
                        deviceId,
                        tableNumber
                      )
                    );
                  } else {
                    dispatch(
                      showNotificationWithTimeout("Invalid QR code", "error")
                    );
                  }
                }
              }}
              ref={qrCameraRef}
              onError={(err) =>
                dispatch(showNotificationWithTimeout(err, "error"))
              }
            />
          )}

          {/* <div className="screen">
            <p className="msg">Place code inside the box. </p>
          </div> */}
          <section class="bottom-sec">
            <div
              class="green-sec"
              onClick={() => {
                document.body.classList.remove("modal-open");
                setScannerModal(false);
              }}
            >
              Cancel
            </div>
          </section>
        </section>
        <Modal
          show={tableReducer?.show_table_not_found_modal}
          title="Sorry, this outlet is not ready to take your order at this time. Please ask your server for assistance."
        />
      </section>
    </div>
  );
};
export default Login;
