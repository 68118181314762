import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Motion, spring, presets } from "react-motion";
import { useSwipeable, config } from "react-swipeable";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";
import ImageViewer from "../Components/ImageViewer";
import ImageSlider from "../Components/ImageSlider";
import {
  addItemCartDetail,
  updateItemCartDetail,
  removeItemCartDetail,
  getTableDetailByFilter,
} from "../../Redux/actions";
import { squreImgLgPngIcon } from "../../Assets";
import {
  getModifierActionName,
  getModifierTypeName,
  getModifierActionSign,
  checkNegNum,
  makeNegToPosNum,
  checkItemExistInCart,
  groupByArray,
  getRandomString,
} from "../../helpers";

function AddItem({
  loading,
  selected_item_detail,
  edited_item_detail,
  table_detail,
  filters,
  filter_tag_detail,
  cart_detail,
  addItemCartDetail,
  updateItemCartDetail,
  getTableDetailByFilter,
  removeItemCartDetail,
}) {
  const history = useHistory();

  const isContainDiscountArr =
    selected_item_detail?.PosMenuitemsModifier.findIndex(
      (p) => p.action == "discount"
    ) > -1
      ? true
      : false;
  const serviceChargePer = parseFloat(table_detail?.ServiceCharges ?? 0);
  const salesTaxPer = parseFloat(table_detail?.SalesTaxRate ?? 0);

  const [wrapperClass, setWrapperClass] = useState("");
  const cartItemKey =
    cart_detail && cart_detail.length > 0 && selected_item_detail?.id
      ? cart_detail.findIndex((p) => p.id == selected_item_detail.id)
      : -1;
  const editedItemSelectedKey = edited_item_detail?.modifierArray?.findIndex(
    (p) => p.action == "discount"
  );
  const defaultSelectedItemPrice =
    editedItemSelectedKey !== undefined && editedItemSelectedKey > -1
      ? parseFloat(
          edited_item_detail?.modifierArray?.[editedItemSelectedKey]
            ?.modifier_price
        )
      : parseFloat(
          selected_item_detail?.dinein_price?.DineIn
            ? selected_item_detail?.dinein_price?.DineIn
            : 0
        );

  const [currentItemQyt, setCurrentItemQyt] = useState(
    edited_item_detail?.quantity != undefined ||
      edited_item_detail?.quantity > 0
      ? edited_item_detail?.quantity
      : 1
  );
  const currentItemQytRef = useRef(currentItemQyt);
  currentItemQytRef.current = currentItemQyt;

  const [selectedItemPrice, setSelectedItemPrice] = useState(
    defaultSelectedItemPrice
  );
  const [totalPrice, setTotalPrice] = useState(parseFloat(selectedItemPrice));
  const [hideHeader, setHideHeader] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);
  const [showImageView, setShowImageView] = useState(false);
  const [modifierChanged, setModifierChanged] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [modifierArray, setModifierArray] = useState(
    edited_item_detail?.modifierArray != null ||
      edited_item_detail?.modifierArray?.length > 0
      ? edited_item_detail?.modifierArray
      : []
  );
  const [comment, setComment] = useState(edited_item_detail?.comment ?? "");
  console.log("modifierArray", modifierArray);
  console.log("selectedOption", selectedOption);

  function calTotalPrice() {
    const currentQyt = currentItemQytRef.current;
    const modifierAddPrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "add" || item.action == "swap")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierRemovePrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "remove")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierSizePrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "size")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const priceWithModifier =
      selectedItemPrice * currentQyt +
      modifierAddPrice * currentQyt -
      modifierRemovePrice * currentQyt +
      modifierSizePrice * currentQyt;
    setTotalPrice(parseFloat(priceWithModifier.toFixed(2)));
  }

  useEffect(() => {
    calTotalPrice();
  }, [selectedItemPrice, modifierChanged]);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        isHeaderAllUp === true
      ) {
        setWrapperClass("");
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setWrapperClass("scroll-active");
        setHideHeader(true);
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  const increaseItem = () => {
    const currentQyt = currentItemQyt + 1;
    setCurrentItemQyt(currentQyt);
    setModifierChanged(!modifierChanged);
  };

  const decreaseItem = () => {
    const currentQyt = currentItemQyt > 1 ? currentItemQyt - 1 : currentItemQyt;
    setCurrentItemQyt(currentQyt);
    setModifierChanged(!modifierChanged);
  };

  function getItemModifiersItems(modifers, action) {
    let res = null;
    if (modifers?.length > 0) {
      res = modifers
        .filter((p) =>
          action === "size"
            ? p.action == "size"
            : p.action == "add" || p.action == "remove" || p.action == "swap"
        )
        .map((element, index) => {
          const modifierType = element?.type ?? "";
          const modifierAction = element?.action ?? "";
          if (
            modifierType == "ingredient" ||
            modifierType == "recipe" ||
            modifierAction == "add" ||
            modifierAction == "remove" ||
            modifierAction == "swap" ||
            modifierAction == "size"
          ) {
            const modifierName = getModifierTypeName(modifierType, element);
            return (
              <div className="options" key={index}>
                <input
                  id={`modifier_${element?.id}`}
                  type="checkbox"
                  className="check"
                  checked={
                    modifierArray.findIndex((p) => p.id == element?.id) > -1
                  }
                  onChange={(el) => {
                    if (el?.target?.checked) {
                      const unitPrice =
                        element?.unit_price &&
                        element?.unit_price !== null &&
                        element?.unit_price !== ""
                          ? parseFloat(element?.unit_price)
                          : 0;
                      const elArr = {
                        id: element?.id,
                        modifier_id: element?.id,
                        modifier_group_id: element?.modifier_group_id,
                        action: modifierAction,
                        name: modifierName,
                        unit_price: parseFloat(unitPrice),
                        modifier_price: parseFloat(unitPrice),
                        modifier_quantity: 1,
                      };
                      const existIndex = modifierArray.findIndex(
                        (p) => p.action == modifierAction
                      );
                      if (existIndex > -1 && modifierAction == "size") {
                        setModifierArray([
                          ...modifierArray.slice(0, existIndex),
                          elArr,
                          ...modifierArray.slice(existIndex + 1),
                        ]);
                      } else {
                        setModifierArray([...modifierArray, elArr]);
                      }
                      setModifierChanged(!modifierChanged);
                    } else if (
                      modifierArray.findIndex((p) => p.id == element?.id) > -1
                    ) {
                      setModifierArray(
                        modifierArray.filter(
                          (modifier) => modifier.id != element?.id
                        )
                      );
                      setModifierChanged(!modifierChanged);
                    }
                  }}
                />
                <label htmlFor={`modifier_${element?.id}`}>
                  {" "}
                  {/* {getModifierActionName(modifierAction)}{" "} */}
                  {getModifierTypeName(modifierType, element)}
                </label>

                {element?.unit_price !== null &&
                  element?.unit_price !== "" &&
                  (element?.unit_price > 0 || element?.action == "size") && (
                    <span className="price-sm">
                      {" "}
                      {getModifierActionSign(modifierAction)}{" "}
                      {checkNegNum(element.unit_price) ? "- " : ""}
                      {`${table_detail?.currency ?? ""}`}
                      {parseFloat(
                        checkNegNum(element.unit_price)
                          ? makeNegToPosNum(element.unit_price)
                          : element.unit_price
                      ).toFixed(2)}
                    </span>
                  )}
              </div>
            );
          }
        });
    }
    return res;
  }

  function getItemModifiers(action = "") {
    let res = null;
    if (
      selected_item_detail?.PosMenuitemsModifier &&
      selected_item_detail?.PosMenuitemsModifier.length > 0
    ) {
      const groupedModifiers = groupByArray(
        selected_item_detail?.PosMenuitemsModifier.filter((p) =>
          action === "size"
            ? p.action == "size"
            : p.action == "add" || p.action == "remove" || p.action == "swap"
        ),
        "modifier_group_id"
      );
      const groupedModifiersArr = Object.values(groupedModifiers);
      if (groupedModifiersArr) {
        res = groupedModifiersArr.map((group) => {
          if (group.length > 0)
            return (
              <>
                <h5 className="sm-title pl-1">
                  {group?.[0]?.["modifier_group_name"]}
                </h5>
                {getItemModifiersItems(group, action)}
              </>
            );
        });
      }
    }
    return res;
  }

  function getItemDiscountModifiers() {
    let res = null;
    if (
      selected_item_detail?.PosMenuitemsModifier &&
      selected_item_detail?.PosMenuitemsModifier.length > 0
    ) {
      const discountModifiersArr = selected_item_detail?.PosMenuitemsModifier.filter(
        (p) => p.action == "discount"
      ).map((element, index) => {
        const modifierType = element?.type ?? "";
        const modifierAction = element?.action ?? "";
        const modifierName = getModifierTypeName(modifierType, element);
        const unitPrice =
          element?.unit_price &&
          element?.unit_price !== null &&
          element?.unit_price !== ""
            ? parseFloat(element?.unit_price)
            : 0;
        return (
          <li>
            <input
              type="radio"
              name="select_options"
              id={`modifier_${element?.id}`}
              className="check"
              onClick={() => {
                const discountEl = {
                  id: element?.id,
                  modifier_id: element?.id,
                  modifier_group_id: element?.modifier_group_id,
                  action: modifierAction,
                  name: modifierName,
                  unit_price: parseFloat(unitPrice),
                  modifier_price: parseFloat(unitPrice),
                  modifier_quantity: element?.amount_per_item,
                };
                const existIndex = modifierArray.findIndex(
                  (p) => p.action == "discount"
                );
                if (existIndex > -1) {
                  setModifierArray([
                    ...modifierArray.slice(0, existIndex),
                    discountEl,
                    ...modifierArray.slice(existIndex + 1),
                  ]);
                } else {
                  setModifierArray([...modifierArray, discountEl]);
                }
                setSelectedItemPrice(parseFloat(unitPrice));
              }}
              checked={modifierArray.findIndex((p) => p.id == element?.id) > -1}
            />
            <label htmlFor={`modifier_${element?.id}`}>
              {element?.amount_per_item} x {selected_item_detail?.pos_name}
            </label>
            <span className="price">
              {`${table_detail?.currency ?? ""}`}
              {parseFloat(unitPrice).toFixed(2)}
            </span>
          </li>
        );
      });
      res = (
        <div className="options">
          <ul>
            <li>
              <input
                type="radio"
                name="select_options"
                id={`default_options`}
                className="check"
                onClick={() => {
                  setModifierArray(
                    modifierArray.filter(
                      (modifier) => modifier.action != "discount"
                    )
                  );
                  setSelectedItemPrice(defaultSelectedItemPrice);
                }}
                checked={
                  modifierArray.findIndex((p) => p.action == "discount") < 0
                }
              />
              <label htmlFor="default_options">
                1 x {selected_item_detail?.pos_name}
              </label>
              <span className="price">
                {`${table_detail?.currency ?? ""}`}
                {parseFloat(defaultSelectedItemPrice).toFixed(2)}
              </span>
            </li>
            {discountModifiersArr}
          </ul>
        </div>
      );
    }
    return res;
  }

  function getItemStandardModifiers() {
    let res = null;
    if (
      selected_item_detail?.StandardModifier &&
      selected_item_detail?.StandardModifier.length > 0
    ) {
      res = selected_item_detail?.StandardModifier.map((stanEl, stanIndex) => {
        return (
          <>
            <h5 className="sm-title pl-1" key={stanIndex}>
              {stanEl?.ModifierClass?.name ?? ""}
            </h5>
            {stanEl?.ModifierClass?.Modifier?.map((element, index) => {
              const modifierId = element?.id ? `SM-${element?.id}` : "";
              const modifierType = element?.type ?? "";
              const modifierAction = stanEl?.ModifierClass?.name ?? "";
              const modifierName = element?.name ?? "";
              return (
                <div className="options" key={index}>
                  <input
                    id={`standard_modifier_${modifierId}`}
                    type="checkbox"
                    className="check"
                    checked={
                      modifierArray.findIndex((p) => p.id == modifierId) > -1
                    }
                    onChange={(el) => {
                      if (el?.target?.checked) {
                        const addEl = {
                          id: modifierId,
                          modifier_id: modifierId,
                          action: modifierAction,
                          name: modifierName,
                          unit_price: 0,
                          modifier_price: 0,
                          modifier_quantity: 1,
                        };
                        const existIndex = modifierArray.findIndex(
                          (p) => p.action == modifierAction
                        );
                        if (existIndex > -1) {
                          setModifierArray([
                            ...modifierArray.slice(0, existIndex),
                            addEl,
                            ...modifierArray.slice(existIndex + 1),
                          ]);
                        } else {
                          setModifierArray([...modifierArray, addEl]);
                        }
                      } else if (
                        modifierArray.findIndex((p) => p.id == modifierId) > -1
                      ) {
                        setModifierArray(
                          modifierArray.filter(
                            (modifier) => modifier.id != modifierId
                          )
                        );
                      }
                    }}
                  />
                  <label htmlFor={`standard_modifier_${modifierId}`}>
                    {" "}
                    {/* {getModifierActionName(modifierAction)}{" "} */}
                    {modifierName}
                  </label>
                </div>
              );
            })}
          </>
        );
      });
    }
    return res;
  }

  function getItemTags() {
    let res = null;
    if (
      selected_item_detail?.is_popular &&
      selected_item_detail?.is_popular === 1
    ) {
      res = <span className="tags">Popular</span>;
    } else if (
      selected_item_detail?.is_trending &&
      selected_item_detail?.is_trending === 1
    ) {
      res = <span className="tags blue">Trending</span>;
    }
    return res;
  }

  function getItemFilterTags(item) {
    let res = null;
    if (item?.allergy_tags && item?.allergy_tags.length > 0) {
      res = (
        <ul>
          {item?.allergy_tags.map(function (tag, index) {
            const filterTagKey =
              filter_tag_detail && filter_tag_detail.length > 0
                ? filter_tag_detail.findIndex((p) => p.symbol == tag)
                : null;
            const filterTag =
              filterTagKey !== null && filterTagKey !== undefined
                ? filter_tag_detail[filterTagKey]
                : null;
            const style =
              filterTag?.color && filterTag?.color !== ""
                ? { background: filterTag?.color ?? "" }
                : {};
            const className =
              filterTag?.color && filterTag?.color !== "" ? "" : "gray";
            return (
              <li style={style} className={className} key={index}>
                {filterTag?.symbol ?? ""}
              </li>
            );
          })}
        </ul>
      );
    }
    return res;
  }

  function AddToCartHandler() {
    if (selected_item_detail?.id) {
      const modifierAddPrice =
        modifierArray.length > 0
          ? modifierArray
              .filter((item) => item.action == "add" || item.action == "swap")
              .map((item) => item.unit_price)
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
      const modifierRemovePrice =
        modifierArray.length > 0
          ? modifierArray
              .filter((item) => item.action == "remove")
              .map((item) => item.unit_price)
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
      const modifierSizePrice =
        modifierArray.length > 0
          ? modifierArray
              .filter((item) => item.action == "size")
              .map((item) => item.unit_price)
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
      const priceWithModifier =
        selectedItemPrice * currentItemQyt +
        modifierAddPrice * currentItemQyt -
        modifierRemovePrice * currentItemQyt +
        modifierSizePrice * currentItemQyt;
      const singlePriceWithModifier =
        selectedItemPrice +
        modifierAddPrice -
        modifierRemovePrice +
        modifierSizePrice;
      const serviceChargeAmount =
        selected_item_detail?.dinein_price?.service_charge == true
          ? parseFloat(
              (parseFloat(priceWithModifier ?? 0) * serviceChargePer) / 100
            )
          : 0;
      const salesTaxAmount =
        selected_item_detail?.dinein_price?.sales_tax == true
          ? parseFloat(
              ((priceWithModifier + serviceChargeAmount) * salesTaxPer) / 100
            )
          : 0;
      let item = {
        id: selected_item_detail?.id,
        offline_id: getRandomString(),
        is_fired: false,
        is_hold: false,
        name: selected_item_detail?.pos_name,
        image: selected_item_detail?.image,
        is_added: false,
        is_service_charge:
          selected_item_detail?.dinein_price?.service_charge ?? false,
        is_sales_tax: selected_item_detail?.dinein_price?.sales_tax ?? false,
        quantity: currentItemQyt,
        amount: singlePriceWithModifier,
        amount_without_modifiers: parseFloat(selectedItemPrice),
        item_amount: selectedItemPrice,
        actual_menu_price: parseFloat(
          selected_item_detail?.dinein_price?.DineIn ?? 0
        ),
        comment: comment,
        total: priceWithModifier,
        menu_item_type: selected_item_detail?.menu_item_type ?? "",
        menu_price: selected_item_detail?.menu_price ?? "",
        service_charge_amount: serviceChargeAmount,
        sales_tax_amount: salesTaxAmount,
        catID: selected_item_detail?.other_category_id ?? "",
        sub_category_id: selected_item_detail?.sub_category_id ?? "",
        modifierArray: modifierArray.sort(function (a, b) {
          return a.id - b.id;
        }),
      };
      const checkItemExistInCartKey = checkItemExistInCart(item, cart_detail);
      if (
        (edited_item_detail !== null &&
          edited_item_detail?.offline_id !== undefined) ||
        checkItemExistInCartKey > -1
      ) {
        const existOffId =
          checkItemExistInCartKey > -1
            ? checkItemExistInCartKey
            : cart_detail?.findIndex(
                (p) => p.offline_id == edited_item_detail.offline_id
              );
        if (existOffId > -1) {
          updateItemCartDetail(existOffId, item);
        } else addItemCartDetail(item);
      } else {
        addItemCartDetail(item);
      }
      getTableDetailByFilter(
        table_detail?.PosTableMaster?.qr_code_id,
        filters,
        ""
      );
      history.push(
        `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
      );
    }
    return;
  }
  function getAddItemDetail() {
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <div className={"wrapper " + wrapperClass}>
          <section className="add-items_sec">
            <Motion
              defaultStyle={{ x: 270 }}
              style={{
                x: spring(hideHeader === false ? 270 : 0, {
                  precision: 1,
                  stiffness: 300,
                  damping: 44,
                }),
              }}
            >
              {({ x }) => (
                <div
                  className="image-container-sec"
                  style={{
                    height: `${x}px`,
                  }}
                >
                  <div
                    onClick={() =>
                      history.push(
                        `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                      )
                    }
                    className="cross-btn"
                  >
                    {" "}
                  </div>
                  <div className="image-sec">
                    <ImageSlider
                      images={
                        selected_item_detail?.content?.length > 0
                          ? selected_item_detail?.content
                          : [{ file_name: selected_item_detail?.image }]
                      }
                      onOpen={(index) => {
                        setShowImageView(true);
                        setCurrentImageIndex(index);
                      }}
                      // isOpen={true}
                    />
                    {showImageView === true && (
                      <ImageViewer
                        images={
                          selected_item_detail?.content?.length > 0
                            ? selected_item_detail?.content
                            : [{ file_name: selected_item_detail?.image }]
                        }
                        isOpen={showImageView}
                        currentIndex={currentImageIndex}
                        onClose={() => setShowImageView(false)}
                      />
                    )}
                    {/* <figure>
                      {" "}
                      <ImageTag
                        src={selected_item_detail?.image}
                        defaultimage={table_detail?.Merchant?.logo}
                      />
                    </figure> */}
                    {getItemTags()}
                  </div>
                </div>
              )}
            </Motion>
            <Motion
              defaultStyle={{ x: 270 }}
              style={{
                x: spring(hideHeader === false ? 0 : 84, {
                  precision: 1,
                  stiffness: 300,
                  damping: 44,
                }),
              }}
            >
              {({ x }) => (
                <div
                  className={`foobar-header row mx-0 ${
                    hideHeader === true ? "touch-top" : ""
                  }`}
                  style={{
                    height: `${x}px`,
                  }}
                >
                  <div
                    onClick={() =>
                      history.push(
                        `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                      )
                    }
                    className="cross-btn"
                  >
                    {" "}
                  </div>
                  <h5 className="name col-9">
                    {selected_item_detail?.pos_name}
                  </h5>
                  <span className="price col-3 px-0 text-right h5">
                    {" "}
                    {!isContainDiscountArr && (
                      <>
                        <small className="currency">{`${
                          table_detail?.currency ?? ""
                        }`}</small>
                        {`${
                          selected_item_detail?.dinein_price?.DineIn
                            ? parseFloat(
                                selected_item_detail?.dinein_price?.DineIn
                              ).toFixed(2)
                            : "0.00"
                        }`}
                      </>
                    )}
                  </span>
                  {/* <p className="description col-12">
                    {selected_item_detail?.description}
                  </p> */}
                </div>
              )}
            </Motion>
            <div
              className={`item-details ${
                hideHeader === true ? "top-scroll" : ""
              }`}
              onScroll={scrolledElement}
              {...handlers}
            >
              <div className="col-12 product-details">
                <h5 className="name col-9">
                  {" "}
                  {selected_item_detail?.pos_name}{" "}
                </h5>
                <span className="price col-3">
                  {" "}
                  {!isContainDiscountArr && (
                    <>
                      <small className="currency">{`${
                        table_detail?.currency ?? ""
                      }`}</small>
                      {`${
                        selected_item_detail?.dinein_price?.DineIn
                          ? parseFloat(
                              selected_item_detail?.dinein_price?.DineIn
                            ).toFixed(2)
                          : "0.00"
                      }`}
                    </>
                  )}
                </span>
                <p className="description">
                  {selected_item_detail?.description}
                </p>
                <div className="labels">
                  {getItemFilterTags(selected_item_detail)}
                </div>
                {isContainDiscountArr && getItemDiscountModifiers()}
                {/* NOT IN USE */}
                {/* {cartItemKey > -1 && (
                  <div className="options">
                    <ul>
                      <li>
                        <input
                          type="radio"
                          name="select_options"
                          id="default_options"
                          className="check"
                          onClick={() => {
                            setCurrentItemQyt(1);
                            setTotalPrice(selectedItemPrice);
                            setSelectedOption(null);
                            setModifierArray([]);
                          }}
                          checked={selectedOption === null ? true : false}
                        />
                        <label htmlFor="default_options">
                          1 x {selected_item_detail?.pos_name}
                        </label>
                        <span className="price">
                          {`${table_detail?.currency ?? ""}`}
                          {selectedItemPrice}
                        </span>
                      </li>
                      {cart_detail
                        .filter((item) => item.id == selected_item_detail?.id)
                        .map((item, index) => {
                          return (
                            <li key={index}>
                              <input
                                type="radio"
                                name="select_options"
                                id={`options_${index}`}
                                className="check"
                                onClick={() => {
                                  setCurrentItemQyt(item?.quantity);
                                  setTotalPrice(item?.total);
                                  item.index = index;
                                  setModifierArray(item.modifierArray);
                                  setSelectedOption(item);
                                }}
                                checked={
                                  selectedOption !== null &&
                                  selectedOption?.index == item.index
                                }
                              />
                              <label htmlFor={`options_${index}`}>
                                {item.quantity} x {item.name}
                              </label>
                              <span className="price">
                                {`${table_detail?.currency ?? ""}`}
                                {parseFloat(item.total).toFixed(2)}
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )} */}
              </div>
              {selected_item_detail?.PosMenuitemsModifier &&
                selected_item_detail?.PosMenuitemsModifier.length > 0 && (
                  <>
                    <h5 className="sm-title"> Modifiers </h5>

                    <div className="selectors">
                      {selected_item_detail?.PosMenuitemsModifier.findIndex(
                        (p) => p.action == "size"
                      ) > -1 && <>{getItemModifiers("size")}</>}
                      {getItemModifiers("")}
                      {getItemStandardModifiers()}
                      <div className="message_col p-0 mt-2">
                        <label>Notes:</label>
                        <textarea
                          id={`comment_${selected_item_detail?.id}`}
                          name={`comment_${selected_item_detail?.id}`}
                          className={"form-control"}
                          placeholder="Add note here."
                          type="text"
                          onChange={(el) => {
                            setComment(el.target.value);
                          }}
                        >
                          {comment}
                        </textarea>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </section>

          <section className="bottom-sec">
            <div className="adding-items">
              <div className="items-sec">
                <button className="remove-btn" onClick={decreaseItem}>
                  {" "}
                </button>
                <input
                  type="text"
                  className="items-num"
                  value={currentItemQyt}
                  readOnly
                />
                <button className="add-btn" onClick={increaseItem}>
                  {" "}
                </button>
              </div>
              <div className="price-sec">
                <strong>
                  {" "}
                  <small> {`${table_detail?.currency ?? ""}`}</small>
                  {parseFloat(totalPrice).toFixed(2)}
                </strong>
                <p> item total </p>
              </div>
            </div>
            <button className="green-btn" onClick={AddToCartHandler}>
              {" "}
              Add to Basket{" "}
            </button>
          </section>
        </div>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getAddItemDetail()}</>;
}
const mapStateToProps = (state) => ({
  selected_item_detail: state.tableDetailReducer.selected_item_detail,
  edited_item_detail: state.tableDetailReducer.edited_item_detail,
  table_detail: state.tableDetailReducer.table_detail,
  filters: state.tableDetailReducer.filters,
  filter_tag_detail: state.tableDetailReducer.filter_tag_detail,
  cart_detail: state.cartDetailReducer.cart_detail,
  loading: state.loaderReducer.loading,
});

const mapDispatchToProps = {
  addItemCartDetail: addItemCartDetail,
  updateItemCartDetail: updateItemCartDetail,
  removeItemCartDetail: removeItemCartDetail,
  getTableDetailByFilter: getTableDetailByFilter,
};

AddItem = connect(mapStateToProps, mapDispatchToProps)(AddItem);
export default AddItem;
