import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Draggable from "react-draggable";
import ImageTag from "./ImageTag";
import { useSelector, useDispatch } from "react-redux";
import {
  getSelectedItemDetail,
  getEditedItemDetail,
  showNotificationWithTimeout,
} from "../../Redux/actions";
import {
  getModifierActionSign,
  getModifierActionName,
  checkNegNum,
  makeNegToPosNum,
} from "../../helpers";
import { userIcon, removeIcon } from "../../Assets";

const DraggableItem = (
  { item, defaultimage, itemIndex, removeItemHandler },
  props
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [wrapperClass, setWrapperClass] = useState("");
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const table_detail = useSelector(
    (state) => state?.tableDetailReducer?.table_detail
  );

  function DraggableEventHandler(event, data) {
    if (event.type === "touchend") {
      setPosition({ x: data.lastX < -50 ? -185 : 0, y: 0 });
      setWrapperClass("");
      // if (data.lastX <= -185) {
      //   removeItemHandler(itemIndex);
      // }
    }
    // else {
    //   setPosition({ x: data.deltaX, y: 0 });
    //   if (event.type === "touchmove") {
    //     if (data.deltaX < 0) {
    //       setWrapperClass("red");
    //     }
    //   }
    // }
  }

  function getSameBundleItemIndex(bundle) {
    let res = -1;
    if (item?.BundledMenuItem?.length > 0) {
      for (let index = 0; index < item?.BundledMenuItem?.length; index++) {
        const element = item.BundledMenuItem[index];
        if (
          element.id != bundle.id &&
          element.option_series_id == bundle.option_series_id
        ) {
          res = index;
          break;
        }
      }
    }
    return res;
  }

  function getModifierItems(item) {
    let res = null;
    if (item?.menu_item_type == "standard" && item?.modifierArray?.length > 0) {
      res = item.modifierArray
        .filter((p) => p.action != "discount")
        .map((modifier, modifierIndex) => {
          const priceTag = `(${getModifierActionSign(modifier.action)}${
            table_detail?.currency ?? ""
          }${parseFloat(modifier.unit_price).toFixed(2)})`;
          console.log("modifier.unit_price", modifier.unit_price);
          return (
            <ul className="other-options" key={modifierIndex}>
              {modifier.unit_price !== 0 && (
                <li>
                  {`${getModifierActionName(modifier.action)} ${modifier.name}`}{" "}
                  ({checkNegNum(modifier.unit_price) ? "- " : ""}
                  {`${table_detail?.currency ?? ""}`}
                  {parseFloat(
                    checkNegNum(modifier.unit_price)
                      ? makeNegToPosNum(modifier.unit_price)
                      : modifier.unit_price
                  ).toFixed(2)}
                  )
                </li>
              )}
              {modifier.unit_price === 0 && <li>{`${modifier.name}`}</li>}
            </ul>
          );
        });
    } else if (
      item?.menu_item_type == "bundled" &&
      item?.BundledMenuItem?.length > 0
    ) {
      res = item.BundledMenuItem.sort(
        (a, b) => parseInt(a.option_series_id) - parseInt(b.option_series_id)
      ).map((bundle, bundleIndex) => {
        let modiferArr = null;
        if (bundle?.bundledMenuitemModifers?.length > 0) {
          modiferArr = bundle.bundledMenuitemModifers
            .filter((p) => p.action != "discount")
            .map((modifier, modifierIndex) => {
              const priceTag =
                modifier.unit_price > 0
                  ? `(@${parseFloat(modifier.unit_price).toFixed(2)})`
                  : "";
              return (
                <ul className="other-options" key={modifierIndex}>
                  <li>
                    {modifier.unit_price !== 0 && (
                      <>
                        {`${getModifierActionName(modifier.action)} ${
                          modifier.name
                        }`}{" "}
                        ({checkNegNum(modifier.unit_price) ? "- " : ""}
                        {`${table_detail?.currency ?? ""}`}
                        {parseFloat(
                          checkNegNum(modifier.unit_price)
                            ? makeNegToPosNum(modifier.unit_price)
                            : modifier.unit_price
                        ).toFixed(2)}
                        )
                      </>
                    )}
                    {modifier.unit_price === 0 && <>{`${modifier.name}`}</>}
                    {`${
                      modifierIndex + 1 != bundle.bundledMenuitemModifers.length
                        ? ","
                        : ""
                    }`}
                  </li>
                </ul>
              );
            });
        }
        const bundlePriceTag =
          bundle.amount > 0 ? `(@${parseFloat(bundle.amount).toFixed(2)})` : "";
        const bundleQuantityTag =
          bundle.quantity > 1 ? ` x ${parseInt(bundle.quantity)} ` : " ";
        let alreadyExist = getSameBundleItemIndex(bundle);
        const showComma =
          alreadyExist > -1 && alreadyExist < bundleIndex ? true : false;
        return (
          <ul className="other-options" key={bundleIndex}>
            <li>
              {showComma && alreadyExist > -1 ? "," : ""}
              {!showComma ? `${bundle?.option_series_name}:` : ""}{" "}
              {bundle?.bundle_menu_item_name}
              {`${bundleQuantityTag}`}
              {`${bundlePriceTag}`}
              {modiferArr}
            </li>
          </ul>
        );
      });
    }
    return res;
  }

  return (
    <div className="draggable_bg">
      <div>
        <div className={` new_items ${wrapperClass}`}>
          <div className={`menu-box${item?.is_added == true ? " added" : ""}`}>
            <Draggable
              allowAnyClick={false}
              axis="x"
              removeItemHandler
              handle=".orderMenu"
              defaultPosition={position}
              position={position}
              grid={[0.1, 0]}
              bounds={{ right: 0, left: -185, top: 0, bottom: 0 }}
              scale={1}
              disabled={item?.is_added == true ? true : false}
              onStart={DraggableEventHandler}
              onDrag={DraggableEventHandler}
              onStop={DraggableEventHandler}
            >
              <div className="d-flex orderMenu">
                <figure className="menu-img">
                  <ImageTag
                    src={item.image}
                    defaultimage={defaultimage}
                    removeImage={true}
                  />
                </figure>
                <div className="col-7 menu-content pl-0">
                  <h4>
                    <span className="txt-green">{item.quantity}x</span>{" "}
                    {item.name} @{parseFloat(item?.item_amount).toFixed(2)}{" "}
                  </h4>
                  {getModifierItems(item)}
                </div>
                <div className="col-2 px-0 text-right">
                  <p className="pricing-lg">
                    {" "}
                    <small>$</small>
                    {parseFloat(item.total).toFixed(2)}{" "}
                  </p>
                </div>
              </div>
            </Draggable>

            <div class="col-6 edit_delete">
              <a
                href="javascript:void(0);"
                onClick={() => {
                  setPosition({ x: 0, y: 0 });
                  removeItemHandler(itemIndex);
                }}
                class="square-img delete-btn"
              >
                <h5>Delete</h5>
              </a>
              <a
                href="javascript:void(0);"
                role="button"
                onClick={() => {
                  const selectedSubCatItemIndex = table_detail?.SubCategory?.findIndex(
                    (p) => p.id == item.sub_category_id
                  );
                  const selectedSubCatItem =
                    table_detail?.SubCategory?.[selectedSubCatItemIndex] ??
                    undefined;
                  const selectedItemIndex = selectedSubCatItem
                    ? selectedSubCatItem?.MenuItem?.findIndex(
                        (p) => p.id == item.id
                      )
                    : -1;
                  const selectedItem =
                    selectedItemIndex > -1
                      ? selectedSubCatItem?.MenuItem?.[selectedItemIndex]
                      : undefined;
                  if (selectedItem) {
                    dispatch(getSelectedItemDetail(selectedItem));
                    dispatch(getEditedItemDetail(item));
                    if (selectedItem?.menu_item_type == "bundled")
                      history.push("/bundle-add-item");
                    else history.push("/add-item");
                  } else {
                    dispatch(
                      showNotificationWithTimeout(`Invalid item`, "error")
                    );
                  }
                }}
                class="square-img mr-2 edit-btn"
              >
                <h5>Edit</h5>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DraggableItem;
