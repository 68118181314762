import { put, takeLatest } from "redux-saga/effects";
import {
  GET_TABLE_DETAIL,
  TABLE_DETAIL_RECEIVED,
  LOADING_STATUS,
  FILTER_TAG_DETAIL_RECEIVED,
  ADD_FILTER_TAG,
  ADD_FILTER_TAG_RECEIVED,
  ADD_SEARCH_TAG_RECEIVED,
  ADD_SEARCH_TEXT,
  GET_SELECTED_ITEM_DETAIL,
  SELECTED_ITEM_DETAIL_RECEIVED,
  CHANGE_TABLE_NOT_FOUND_MODAL,
  CHANGE_TABLE_ALREADY_INUSE_MODAL,
} from "../actions/types";

const base_url = process?.env?.REACT_APP_API_URL;
let myHeaders = new Headers({
  "Content-Type": "application/json",
  Accept: "application/json",
  // "Access-Control-Allow-Origin": "*",
});
const defaultErrorMsg = "Something went wrong! try again later.";

function* fetchTableDetail(action) {
  yield put({ type: LOADING_STATUS, status: true });
  const json = yield fetch(
    `${base_url}/qr_code_apis/get_table_details/${action.id}`,
    {
      method: "POST",
      mode: "cors",
      headers: myHeaders,
      body: JSON.stringify({
        device_id: action.deviceId ?? "",
      }),
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) =>
      console.log("fetchTableDetail failed : " + error.message)
    );
  const tagsJson = yield fetch(`${base_url}/qr_code_apis/get_tags`, {
    mode: "cors",
    headers: myHeaders,
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("get_tagsDetail failed : " + error.message));
  if (json?.status && json?.data) {
    sessionStorage.setItem("isTableDataLoaded", JSON.stringify(true));
    yield put({ type: TABLE_DETAIL_RECEIVED, json: json?.data });
    if (json?.data?.occupied == 1) {
      yield put({ type: CHANGE_TABLE_ALREADY_INUSE_MODAL, payload: true });
    }
    if (tagsJson?.status && tagsJson?.data)
      yield put({ type: FILTER_TAG_DETAIL_RECEIVED, json: tagsJson?.data });
  } else {
    if (json?.message !== undefined) {
      yield put({ type: CHANGE_TABLE_NOT_FOUND_MODAL, payload: true });
    }
    console.log(
      `fetchTableDetail error : ${
        json?.message ? json.message : defaultErrorMsg
      }`
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* fetchTableDetailByfilter(action) {
  yield put({ type: ADD_FILTER_TAG_RECEIVED, payload: action.ids });
  yield put({ type: ADD_SEARCH_TAG_RECEIVED, input: action.input });
  yield put({ type: ADD_SEARCH_TEXT, input: action.input });
  // NOT IN USE
  // yield put({ type: LOADING_STATUS, status: true });
  // const json = yield fetch(
  //   `${base_url}/qr_code_apis/get_table_details/${action.table_id}?tag_ids=${action.ids}&search=${action.input}`,
  //   {
  //     mode: "cors",
  //     headers: myHeaders,
  //   }
  // )
  //   .then((response) => response.json())
  //   .then((json) => json)
  //   .catch((error) =>
  //     console.log("fetchTableDetail failed : " + error.message)
  //   );
  // yield put({ type: LOADING_STATUS, status: false });
  // if (json?.status && json?.data) {
  //   yield put({ type: TABLE_DETAIL_RECEIVED, json: json?.data });
  //   yield put({ type: ADD_FILTER_TAG_RECEIVED, payload: action.ids });
  //   yield put({ type: ADD_SEARCH_TAG_RECEIVED, input: action.input });
  // } else {
  //   console.log(
  //     `fetchTableDetail error : ${
  //       json?.message ? json.message : defaultErrorMsg
  //     }`
  //   );
  // }
}

function* fetchSelectedItemDetail(action) {
  yield put({ type: SELECTED_ITEM_DETAIL_RECEIVED, payload: action.id });
}

export default function* tableDetailActionWatcher() {
  yield takeLatest(GET_TABLE_DETAIL, fetchTableDetail);
  yield takeLatest(ADD_FILTER_TAG, fetchTableDetailByfilter);
  yield takeLatest(GET_SELECTED_ITEM_DETAIL, fetchSelectedItemDetail);
}
