import {
  AddItemScreen,
  BundleAddItemScreen,
  CartScreen,
  NewItemsScreen,
  ConfirmationScreen,
  PromotionsScreen,
  TabScreen,
} from "../Scenes/Screens";

export const tableRoutes = [
  {
    path: "/cart",
    component: CartScreen,
  },
  {
    path: "/new-items",
    component: ConfirmationScreen,
  },
  {
    path: "/add-item",
    component: AddItemScreen,
  },
  {
    path: "/bundle-add-item",
    component: BundleAddItemScreen,
  },
  {
    path: "/promotions",
    component: PromotionsScreen,
  },
  {
    path: "/tab-screen",
    component: TabScreen,
  },
];
