import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";

const Modal = ({
  show,
  title = "",
  onCancel,
  OnSubmit,
  cancelBtnText = "Cancel",
  submitBtnText = "Submit",
}) => {
  const tableReducer = useSelector((state) => state.tableDetailReducer);
  return (
    <div className={`modal_comp ${show ? "show" : ""}`}>
      <div className={`modal loyality_ms `}>
        {/* <div className="close">&times;</div> */}
        <div className="modal_body">
          <p>{title}</p>
        </div>

        {onCancel !== undefined && OnSubmit !== undefined && (
          <div className="modal_footer">
            <button className="btn btn-success" onClick={() => OnSubmit()}>
              {submitBtnText}
            </button>
            <button className="btn btn-danger" onClick={() => onCancel()}>
              {cancelBtnText}
            </button>
          </div>
        )}
      </div>
      <div className="backdrop"></div>
    </div>
  );
};

export default Modal;
