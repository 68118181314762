import React from "react";
import { userIcon } from "../../Assets";

const ImageTag = (props) => {
  const src = props.src !== undefined ? props.src : "";
  const defaultimage =
    props.defaultimage !== undefined ? props.defaultimage : userIcon;
  return (
    <img
      onError={(ev) => {
        if (props?.removeImage === true) {
          ev.target.parentElement.remove();
        } else {
          ev.target.src = defaultimage;
        }
      }}
      src={src}
      {...props}
    />
  );
};

export default ImageTag;
