import {
  ADD_ITEM_CART_DETAIL,
  REMOVE_ITEM_CART_DETAIL,
  EMPTY_CART_DETAIL,
  UPDATE_ITEM_CART_DETAIL,
  CART_DETAIL_RECEIVED,
  ORDER_PLACE_DETAIL_RECEIVED,
  EMPTY_ORDER_PLACE_DETAIL,
  CART_TAX_DETAIL_RECEIVED,
  EMPTY_CART_TAX_DETAIL,
  CART_DETAIL_STATUS_CHANGED,
  CHANGE_ORDER_SOCKET_EMIT,
  ORDER_HISTORY_DETAIL_RECEIVED,
  EMPTY_ORDER_HISTORY_DETAIL,
  VOUCHER_LIST_DETAIL_RECEIVED,
  EMPTY_VOUCHER_LIST_DETAIL,
  RECENT_ORDER_ID_RECEIVED,
  EMPTY_RECENT_ORDER_ID,
} from "../actions/types";

const initialState = {
  cart_detail: [],
  recent_order_detail: null,
  recent_order_id: "",
  order_history_detail: null,
  voucher_list_detail: null,
  order_socket_emit: false,
  total_cart_amount: null,
  total_service_charge: null,
  total_sale_tax: null,
  total_discount: null,
  total_tip: null,
};

const cartDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM_CART_DETAIL:
      return { ...state, cart_detail: [...state.cart_detail, action.item] };
    case UPDATE_ITEM_CART_DETAIL:
      return {
        ...state,
        cart_detail: [
          ...state.cart_detail.slice(0, action.index),
          action.item,
          ...state.cart_detail.slice(action.index + 1),
        ],
      };
    case REMOVE_ITEM_CART_DETAIL:
      return {
        ...state,
        cart_detail: state.cart_detail.filter(
          (item, index) => index !== action.index
        ),
      };
    case CART_DETAIL_RECEIVED:
      return { ...state, cart_detail: action.cart_detail };
    case CART_DETAIL_STATUS_CHANGED:
      return {
        ...state,
        cart_detail: state.cart_detail.map((item) => {
          item.is_added = true;
          return item;
        }),
      };
    case EMPTY_CART_DETAIL:
      return { ...state, cart_detail: [] };
    case ORDER_PLACE_DETAIL_RECEIVED:
      return { ...state, recent_order_detail: action.payload };
    case EMPTY_ORDER_PLACE_DETAIL:
      return { ...state, recent_order_detail: null };
    case RECENT_ORDER_ID_RECEIVED:
      return { ...state, recent_order_id: action.payload };
    case EMPTY_RECENT_ORDER_ID:
      return { ...state, recent_order_id: "" };
    case CART_TAX_DETAIL_RECEIVED:
      return {
        ...state,
        total_cart_amount: action.total_cart_amount ?? null,
        total_service_charge: action.total_service_charge ?? null,
        total_sale_tax: action.total_sale_tax ?? null,
        total_discount: action.total_discount ?? null,
        total_tip: action.total_tip ?? null,
      };
    case EMPTY_CART_TAX_DETAIL:
      return {
        ...state,
        total_cart_amount: null,
        total_service_charge: null,
        total_sale_tax: null,
        total_discount: null,
        total_tip: null,
      };
    case ORDER_HISTORY_DETAIL_RECEIVED:
      return { ...state, order_history_detail: action.payload };
    case EMPTY_ORDER_HISTORY_DETAIL:
      return { ...state, order_history_detail: null };
    case VOUCHER_LIST_DETAIL_RECEIVED:
      return { ...state, voucher_list_detail: action.payload };
    case EMPTY_VOUCHER_LIST_DETAIL:
      return { ...state, voucher_list_detail: null };
    case CHANGE_ORDER_SOCKET_EMIT:
      return { ...state, order_socket_emit: action.payload };
    default:
      return state;
  }
};
export default cartDetailReducer;
