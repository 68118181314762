import React, { Suspense, useState, useEffect } from "react";
import { DeviceUUID } from "device-uuid";
import { connect } from "react-redux";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from "react-router-dom";
import {
  changeOrderSocketEmit,
  emptyCartDetail,
  emptyOrderPlaceDetail,
  showNotificationWithTimeout,
  deletRecentAndCartOrder,
  changeStatusCartDetail,
} from "../Redux/actions";
import io from "socket.io-client";
import Loading from "../Scenes/Components/Loading";
import ToastMessage from "../Scenes/Components/ToastMessage";
import { LoginScreen } from "../Scenes/AuthScreens";
import NotFoundPage from "../Scenes/Components/NotFoundPage";
import { setPageDetails, getCartSubmittedItems } from "../helpers";
import { authRoutes, tableRoutes } from "../Utils";

const Main = ({
  user_detail,
  table_detail,
  page_title,
  loading,
  recent_order_detail,
  order_socket_emit,
  emptyCartDetail,
  emptyOrderPlaceDetail,
  showNotificationWithTimeout,
  deletRecentAndCartOrder,
  changeStatusCartDetail,
}) => {
  const [socket, setSocket] = useState(null);

  console.log("user_detail", user_detail);
  console.log("page_title", page_title);
  var uuid = new DeviceUUID().get();
  console.log("uuid", uuid);

  useEffect(() => {
    setPageDetails({
      title: page_title,
    });
    // const newSocket = io(`http://13.229.17.190:2020`, {
    //   rejectUnauthorized: false,
    //   transports: ["websocket"], // use WebSocket first, if available
    // });
    const newSocket = io(`https://demosocket.gogmgo.com:2020`, {
      rejectUnauthorized: false,
      transports: ["websocket"], // use WebSocket first, if available
      // withCredentials: true,
      // transportOptions: {
      //   polling: {emptyCartDetail
      //     extraHeaders: {
      //       "Access-Control-Allow-Origin": "*",
      //     },
      //   },
      // },
    });
    setSocket(newSocket);
    return () => newSocket.close();
  }, []);
  let history = useHistory();
  console.log("socket", socket);
  if (socket) {
    socket.on("connect", function () {
      console.log("connected");
    });

    socket.on(`pos_to_ordrr_${user_detail?.id}`, (...args) => {
      console.log("order_socket", args);
      if (
        !loading &&
        args?.[0]?.["message"]?.["order_offline_id"] !== undefined &&
        recent_order_detail?.order_list?.length > 0 &&
        recent_order_detail?.order_list?.findIndex(
          (p) =>
            p.order_offline_id != "" &&
            p.order_offline_id == args?.[0]?.["message"]?.["order_offline_id"]
        ) > -1
      ) {
        const pos_order = args?.[0]?.["message"];
        // console.log("order_socket_pos_order", pos_order?.order_status);
        const items = getCartSubmittedItems(pos_order, table_detail);
        // console.log("ordersssss===>items", items);
        // debugger;
        if (!loading && items?.length > 0) {
          changeStatusCartDetail(items);
        }
        if (!loading && pos_order?.order_status == "complete") {
          deletRecentAndCartOrder();
        }
      }
    });
    // socket.on("disconnect", () => this.disconnect());
    socket.on("connect_error", (err) => {
      console.log("connect_error due to", err);
      // console.log(`connect_error due to ${err.message}`);
    });
  }
  return (
    <Suspense fallback={<Loading show={true} />}>
      <Loading show={loading} />
      <ToastMessage />
      <div>
        <Router history={history}>
          <Switch>
            <Route exact path={"/"} component={LoginScreen} />
            {authRoutes.map((route, index) => {
              return (
                <Route
                  exact
                  path={route.path}
                  component={route.component}
                  key={index}
                />
              );
            })}
            {!(user_detail === null || user_detail === undefined) &&
              tableRoutes.map((route, index) => {
                return (
                  <Route
                    exact
                    path={route.path}
                    component={route.component}
                    key={index}
                  />
                );
              })}
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      </div>
    </Suspense>
  );
};
const mapStateToProps = (state) => ({
  isLogin: state.tableDetailReducer.isLogin,
  user_detail: state.authDetailReducer.user_detail,
  table_detail: state.tableDetailReducer.table_detail,
  loading: state.loaderReducer.loading,
  page_title: state.tableDetailReducer.page_title,
  recent_order_detail: state.cartDetailReducer.recent_order_detail,
  order_socket_emit: state.cartDetailReducer.order_socket_emit,
});

const mapDispatchToProps = {
  changeOrderSocketEmit,
  changeStatusCartDetail,
  emptyCartDetail,
  emptyOrderPlaceDetail,
  showNotificationWithTimeout,
  deletRecentAndCartOrder,
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
