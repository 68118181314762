import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

export const Tabbar = ({ activeTab, switchTab }) => {
  return (
    <section className="middle-sec">
      <div className="d-flex m-auto py-4 col-10">
        <div
          onClick={() => switchTab(0)}
          className={`col link-box ${activeTab === 0 ? "active" : ""}`}
          style={{ cursor: "pointer" }}
        >
          <div href="#" className="blue box">
            {" "}
            Account{" "}
          </div>
        </div>
        <div
          onClick={() => switchTab(1)}
          className={`col link-box ${activeTab === 1 ? "active" : ""}`}
          style={{ cursor: "pointer" }}
        >
          <div className="purple box"> Vouchers </div>
        </div>
        <div
          onClick={() => switchTab(2)}
          className={`col link-box ${activeTab === 2 ? "active" : ""}`}
          style={{ cursor: "pointer" }}
        >
          <div className="yellow box"> History </div>
        </div>
      </div>
    </section>
  );
};

export const VoucherTabbar = ({ activeTab, switchTab }) => {
  return (
    <ul className="voucher-sec">
      <li
        onClick={() => switchTab(0)}
        className={`${activeTab === 0 ? "active" : ""}`}
      >
        {" "}
        <a> Active </a>{" "}
      </li>
      <li
        onClick={() => switchTab(1)}
        className={`${activeTab === 1 ? "active" : ""}`}
      >
        {" "}
        <a> Used </a>{" "}
      </li>
      <li
        onClick={() => switchTab(2)}
        className={`${activeTab === 2 ? "active" : ""}`}
      >
        {" "}
        <a> Expired </a>{" "}
      </li>
    </ul>
  );
};
