import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../Components/Header";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";

import AccountPopup from "../Components/AccountPopup";
import { getSelectedItemDetail, orderPlaceDetail } from "../../Redux/actions";
import {
  getModifierActionSign,
  getModifierActionName,
  checkNegNum,
  makeNegToPosNum,
} from "../../helpers";

import {} from "../../Assets";

function Confirmation({
  loading,
  table_detail,
  cart_detail,
  recent_order_detail,
  user_detail,
  orderPlaceDetail,
  getSelectedItemDetail,
}) {
  const [showAccountPopUp, setShowAccountPopUp] = useState(0);
  const defaultTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  const defaultServiceCharge =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_service_charge == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.service_charge_amount);
            }, 0)
        )
      : 0;
  const defaultTotalSaleTax =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_sales_tax == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.sales_tax_amount);
            }, 0)
        )
      : 0;
  const [totalCartAmount, setTotalCartAmount] = useState(
    defaultTotalCartAmount
  );
  const [totalServiceCharge, setTotalServiceCharge] = useState(
    defaultServiceCharge
  );
  const [totalSaleTax, setTotalSaleTax] = useState(defaultTotalSaleTax);
  const [totalTip, setTotalTip] = useState("$0.00");
  const [totalDiscount, setTotalDiscount] = useState(0);

  useEffect(() => {
    const newTotalCartAmount =
      cart_detail && cart_detail.length > 0
        ? cart_detail.reduce(function (sum, current) {
            return sum + parseFloat(current.total);
          }, 0)
        : 0;
    const newServiceCharge =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_service_charge == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.service_charge_amount);
              }, 0)
          )
        : 0;
    const newTotalSaleTax =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_sales_tax == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.sales_tax_amount);
              }, 0)
          )
        : 0;
    setTotalSaleTax(newTotalSaleTax);
    setTotalServiceCharge(newServiceCharge);
    setTotalCartAmount(newTotalCartAmount);
  }, [showAccountPopUp, cart_detail]);

  const jwtSecret = process?.env?.REACT_APP_JWT_SECRET;

  const history = useHistory();

  function getSameBundleItemIndex(item, bundle) {
    let res = -1;
    if (item?.BundledMenuItem?.length > 0) {
      for (let index = 0; index < item?.BundledMenuItem?.length; index++) {
        const element = item.BundledMenuItem[index];
        if (
          element.id != bundle.id &&
          element.option_series_id == bundle.option_series_id
        ) {
          res = index;
          break;
        }
      }
    }
    return res;
  }

  function getModifierItems(item) {
    let res = null;
    if (item?.menu_item_type == "standard" && item?.modifierArray?.length > 0) {
      res = item.modifierArray
        .filter((p) => p.action != "discount")
        .map((modifier, modifierIndex) => {
          const priceTag = `(${getModifierActionSign(modifier.action)}${
            table_detail?.currency ?? ""
          }${parseFloat(modifier.unit_price).toFixed(2)})`;
          console.log("modifier.unit_price", modifier.unit_price);
          return (
            <ul className="other-options" key={modifierIndex}>
              {modifier.unit_price !== 0 && (
                <li>
                  {`${getModifierActionName(modifier.action)} ${modifier.name}`}{" "}
                  ({checkNegNum(modifier.unit_price) ? "- " : ""}
                  {`${table_detail?.currency ?? ""}`}
                  {parseFloat(
                    checkNegNum(modifier.unit_price)
                      ? makeNegToPosNum(modifier.unit_price)
                      : modifier.unit_price
                  ).toFixed(2)}
                  )
                </li>
              )}
              {modifier.unit_price === 0 && <li>{`${modifier.name}`}</li>}
            </ul>
          );
        });
    } else if (
      item?.menu_item_type == "bundled" &&
      item?.BundledMenuItem?.length > 0
    ) {
      res = item.BundledMenuItem.sort(
        (a, b) => parseInt(a.option_series_id) - parseInt(b.option_series_id)
      ).map((bundle, bundleIndex) => {
        let modiferArr = null;
        if (bundle?.bundledMenuitemModifers?.length > 0) {
          modiferArr = bundle.bundledMenuitemModifers
            .filter((p) => p.action != "discount")
            .map((modifier, modifierIndex) => {
              const priceTag =
                modifier.unit_price > 0
                  ? `(@${parseFloat(modifier.unit_price).toFixed(2)})`
                  : "";
              return (
                <ul className="other-options" key={modifierIndex}>
                  <li>
                    {modifier.unit_price !== 0 && (
                      <>
                        {`${getModifierActionName(modifier.action)} ${
                          modifier.name
                        }`}{" "}
                        ({checkNegNum(modifier.unit_price) ? "- " : ""}
                        {`${table_detail?.currency ?? ""}`}
                        {parseFloat(
                          checkNegNum(modifier.unit_price)
                            ? makeNegToPosNum(modifier.unit_price)
                            : modifier.unit_price
                        ).toFixed(2)}
                        )
                      </>
                    )}
                    {modifier.unit_price === 0 && <>{`${modifier.name}`}</>}
                    {`${
                      modifierIndex + 1 != bundle.bundledMenuitemModifers.length
                        ? ","
                        : ""
                    }`}
                  </li>
                </ul>
              );
            });
        }
        const bundlePriceTag =
          bundle.amount > 0 ? `(@${parseFloat(bundle.amount).toFixed(2)})` : "";
        const bundleQuantityTag =
          bundle.quantity > 1 ? ` x ${parseInt(bundle.quantity)} ` : " ";
        let alreadyExist = getSameBundleItemIndex(item, bundle);
        const showComma =
          alreadyExist > -1 && alreadyExist < bundleIndex ? true : false;
        return (
          <ul className="other-options" key={bundleIndex}>
            <li>
              {showComma && alreadyExist > -1 ? "," : ""}
              {!showComma ? `${bundle?.option_series_name}:` : ""}{" "}
              {bundle?.bundle_menu_item_name}
              {`${bundleQuantityTag}`}
              {`${bundlePriceTag}`}
              {modiferArr}
            </li>
          </ul>
        );
      });
    }
    return res;
  }

  function getCartItems() {
    if (cart_detail && cart_detail.length > 0) {
      return cart_detail.map(function (item, itemIndex) {
        return (
          <div className="new_items" key={itemIndex}>
            <div
              className={`menu-box row ${
                item?.is_added == true ? "added" : ""
              }`}
            >
              <div className="d-flex orderMenu">
                <figure className="menu-img">
                  <ImageTag
                    src={item.image}
                    defaultimage={table_detail?.Merchant?.logo}
                    removeImage={true}
                  />
                </figure>
                <div className="col-7 menu-content pl-0">
                  <h4>
                    <span className="txt-green">{item.quantity}x</span>{" "}
                    {item.name} @{parseFloat(item.item_amount).toFixed(2)}{" "}
                  </h4>
                  {getModifierItems(item)}
                </div>
                <div className="col-2 px-0 text-right">
                  <p className="pricing-lg">
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {parseFloat(item.total).toFixed(2)}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    return null;
  }

  function getBasketTotalAmount() {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      res =
        parseFloat(totalCartAmount.toFixed(3).slice(0, -1)) +
        parseFloat(totalServiceCharge.toFixed(3).slice(0, -1)) +
        parseFloat(
          parseFloat(totalTip.toString().replace("$", ""))
            .toFixed(3)
            .slice(0, -1)
        ) +
        parseFloat(totalSaleTax.toFixed(3).slice(0, -1)) -
        parseFloat(totalDiscount.toFixed(3).slice(0, -1));
    }
    return (
      <>
        <small>{`${table_detail?.currency ?? ""}`}</small>
        {parseFloat(res).toFixed(3).slice(0, -1)}
      </>
    );
  }

  function getPopularItems() {
    let res = [];
    if (table_detail?.SubCategory?.length > 0) {
      table_detail?.SubCategory.filter(
        (subCat) => subCat?.MenuItem.findIndex((p) => p.is_popular == 1) > -1
      ).map(function (subCat, index) {
        if (subCat?.MenuItem) {
          subCat?.MenuItem.filter((item) => item.is_popular == 1).map(function (
            item,
            itemKey
          ) {
            res.push(item);
          });
        }
      });
    }
    if (res.length > 0) {
      return res.slice(0, 4).map((item) => {
        return (
          <div
            className="col-6"
            onClick={() => {
              getSelectedItemDetail(item);
              history.push("/add-item");
            }}
          >
            <figure className="menu-img mx-auto mb-3">
              <ImageTag
                src={item?.image}
                defaultimage={table_detail?.Merchant?.logo}
                alt=""
              />
            </figure>
            <h5 className="sm-h5"> {item?.pos_name ?? ""} </h5>
            <p>
              {" "}
              @
              {item?.dinein_price?.DineIn !== undefined
                ? parseFloat(item?.dinein_price?.DineIn).toFixed(3).slice(0, -1)
                : "0.00"}
            </p>
          </div>
        );
      });
    } else {
      return null;
    }
  }

  function getBasketTotalQuantity() {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      res = cart_detail.reduce(function (sum, current) {
        return sum + parseInt(current.quantity);
      }, 0);
    }
    return <div className="items-count"> {res} </div>;
  }

  function getCartDetail() {
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <div className="wrapper cart_wrapper custom_pb">
          <Header
            backTitle={"Add New Items"}
            onTabProfile={() => setShowAccountPopUp(1)}
            backPath={
              table_detail?.PosTableMaster?.qr_code_id !== undefined
                ? `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                : undefined
            }
            rtComponent={
              <>
                <div className="hotel_name">
                  <div className="circle-img">
                    <figure className="">
                      <ImageTag src={table_detail?.Merchant?.logo} alt="" />
                    </figure>
                  </div>
                  <div className="naming-sec text-center">
                    <h4> {table_detail?.Merchant?.account_name} </h4>
                    <p>
                      {" "}
                      Table #{
                        table_detail?.PosTableMaster?.table_number
                      } Basket{" "}
                    </p>
                  </div>
                  <div className="desc-sec-top">
                    <h4> Your order is in the kitchen! </h4>
                    <p>
                      {" "}
                      Please feel free to click the Add New Items buttons or
                      re-scan the QR to add further items to your order.{" "}
                    </p>
                  </div>
                  <div className="col-12 py-3 px-0">
                    <h5 className="md-title bold"> Order Status </h5>
                  </div>
                </div>
              </>
            }
          />
          <section className="middle-sec cart-sec-ms new_items_page">
            {getCartItems()}
          </section>
          {/* {table_detail?.SubCategory &&
            table_detail.SubCategory.findIndex(
              (subCat) =>
                subCat?.MenuItem.findIndex((p) => p.is_popular == 1) > -1
            ) > -1 && (
              <section className="recommended">
                <h3 className="title"> You Might Also Like </h3>
                <div className="row also-like-sec">{getPopularItems()}</div>
              </section>
            )} */}
          <section className="billing-details mb-5">
            <ul className="billing-list">
              <li>
                {" "}
                <span> Discounts </span>{" "}
                <strong>
                  {" "}
                  (<small>{`${table_detail?.currency ?? ""}`}</small>0.00)
                </strong>
              </li>
              <li>
                {" "}
                <span> Sub Total </span>{" "}
                <strong>
                  {" "}
                  <small>{`${table_detail?.currency ?? ""}`}</small>
                  {`${parseFloat(totalCartAmount).toFixed(3).slice(0, -1)}`}
                </strong>
              </li>
              <li>
                {" "}
                <span> Service Charges </span>{" "}
                <strong>
                  {" "}
                  <small>{`${table_detail?.currency ?? ""}`}</small>
                  {parseFloat(totalServiceCharge).toFixed(3).slice(0, -1)}
                </strong>
              </li>

              <li>
                {" "}
                <span> Tax </span>{" "}
                <strong>
                  {" "}
                  <small>{`${table_detail?.currency ?? ""}`}</small>
                  {parseFloat(totalSaleTax).toFixed(3).slice(0, -1)}
                </strong>
              </li>
              <li>
                {" "}
                <span> Gratuity </span>{" "}
                <input
                  type="text"
                  className="type-text"
                  value={totalTip}
                  disabled={true}
                />
              </li>
              <li className="total">
                {" "}
                <span> Total </span> <strong> {getBasketTotalAmount()}</strong>
              </li>
            </ul>
          </section>
          <section className="bottom-sec">
            <div className="green-sec py-3">
              {/* <div className="col-2">{getBasketTotalQuantity()}</div> */}
              <div
                className="col-12 text-center"
                onClick={() => {
                  history.push(
                    `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                  );
                }}
              >
                <strong> Go Back & Add New Items </strong>
              </div>
              <div className="col-2"></div>
            </div>
          </section>
          {/* account modal */}

          <AccountPopup
            show={showAccountPopUp}
            onCancel={() => setShowAccountPopUp(0)}
          />
        </div>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getCartDetail()}</>;
}

const mapStateToProps = (state) => ({
  table_detail: state.tableDetailReducer.table_detail,
  cart_detail: state.cartDetailReducer.cart_detail,
  recent_order_detail: state.cartDetailReducer.recent_order_detail,
  user_detail: state.authDetailReducer.user_detail,
  loading: state.loaderReducer.loading,
});

const mapDispatchToProps = {
  getSelectedItemDetail: getSelectedItemDetail,
  orderPlaceDetail: orderPlaceDetail,
};

Confirmation = connect(mapStateToProps, mapDispatchToProps)(Confirmation);

export default Confirmation;
