import {
  GET_TABLE_DETAIL,
  TABLE_DETAIL_RECEIVED,
  EMPTY_TABLE_DETAIL,
  FILTER_TAG_DETAIL_RECEIVED,
  ADD_SEARCH_TAG_RECEIVED,
  ADD_SEARCH_TEXT,
  ADD_FILTER_TAG_RECEIVED,
  SELECTED_ITEM_DETAIL_RECEIVED,
  CHANGE_TABLE_NOT_FOUND_MODAL,
  CHANGE_TABLE_ALREADY_INUSE_MODAL,
  EDITED_ITEM_DETAIL_RECEIVED,
  EMPTY_EDITED_ITEM_DETAIL,
} from "../actions/types";

const initialState = {
  page_title: "Gogmgo",
  isLogin: false,
  table_detail: null,
  filter_tag_detail: null,
  selected_item_detail: null,
  edited_item_detail: null,
  filters: "",
  search_input: "",
  show_table_not_found_modal: false,
  show_table_already_inuse_modal: false,
};

const tableDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TABLE_DETAIL:
      return {
        ...state,
        table_detail: null,
        show_table_not_found_modal: false,
        show_table_already_inuse_modal: false,
      };
    case EMPTY_TABLE_DETAIL:
      return {
        ...state,
        table_detail: null,
        filter_tag_detail: null,
        selected_item_detail: null,
        edited_item_detail: null,
        filters: "",
        search_text: "",
        search_input: "",
        show_table_not_found_modal: false,
        show_table_already_inuse_modal: false,
      };
    case TABLE_DETAIL_RECEIVED:
      return { ...state, table_detail: action.json, isLogin: true };
    case FILTER_TAG_DETAIL_RECEIVED:
      return { ...state, filter_tag_detail: action.json };
    case ADD_FILTER_TAG_RECEIVED:
      return { ...state, filters: action.payload };
    case ADD_SEARCH_TAG_RECEIVED:
      return { ...state, search_input: action.input };
    case ADD_SEARCH_TEXT:
      return { ...state, search_text: action.input };
    case SELECTED_ITEM_DETAIL_RECEIVED:
      return { ...state, selected_item_detail: action.payload };
    case EDITED_ITEM_DETAIL_RECEIVED:
      return { ...state, edited_item_detail: action.payload };
    case EMPTY_EDITED_ITEM_DETAIL:
      return { ...state, edited_item_detail: null };
    case CHANGE_TABLE_NOT_FOUND_MODAL:
      return { ...state, show_table_not_found_modal: action.payload };
    case CHANGE_TABLE_ALREADY_INUSE_MODAL:
      return { ...state, show_table_already_inuse_modal: action.payload };
    default:
      return state;
  }
};
export default tableDetailReducer;
