import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { DeviceUUID } from "device-uuid";
import { useSwipeable, config } from "react-swipeable";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import {
  getTableDetail,
  emptyTableDetail,
  getTableDetailByFilter,
  getSelectedItemDetail,
  updateSearchText,
  emptyChangeTableAlreadyInuse,
  changeTableNotFound,
  emptyEditedItemDetail,
} from "../../Redux/actions";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import Cart from "./Cart";
import UnableToLoadData from "../Components/UnableToLoadData";
import PleaseWait from "../Components/PleaseWait";
import ImageTag from "../Components/ImageTag";
import RatingInput from "../Components/RatingInput";
import {
  pluckArray,
  getSessionStorageOrDefault,
  checkItemAvailable,
  truncateString,
} from "../../helpers";
import {
  poweredLogo1PngIcon,
  filter as filterImg,
  close_dark,
  reorder,
  ordrrLogoNew,
} from "../../Assets";
import { Motion, spring, presets } from "react-motion";
import AccountPopup from "../Components/AccountPopup";
import Modal from "../Components/Modal";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function Home({
  getTableDetail,
  table_detail,
  user_detail,
  card_detail,
  loading,
  filters,
  search_input,
  search_text,
  emptyTableDetail,
  filter_tag_detail,
  cart_detail,
  selected_item_detail,
  getTableDetailByFilter,
  getSelectedItemDetail,
  emptyEditedItemDetail,
  updateSearchText,
  emptyChangeTableAlreadyInuse,
  changeTableNotFound,
  show_table_already_inuse_modal,
  show_table_not_found_modal,
}) {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  const scrollToBottom = () => {
    scroll.scrollToBottom();
  };
  function scrollTo() {
    scroll.scrollTo(100);
  }
  const scrollMore = () => {
    scroll.scrollMore(100);
  };
  const handleSetActive = (to, element) => {
    if (document.getElementById(`${to}`)) {
      document.getElementById(`${to}`).scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
  };
  const deviceid = new DeviceUUID().get();
  const isTableDataLoaded = getSessionStorageOrDefault(
    "isTableDataLoaded",
    false
  );
  const subCategoryLength = table_detail?.SubCategory?.length;
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [lastScrollHeight, setLastScrollHeight] = useState([]);
  const subCatLength =
    table_detail?.SubCategory && table_detail?.SubCategory.length > 0
      ? table_detail?.SubCategory.length
      : 0;
  const isFilterOrSearchApplied =
    (filters && filters !== "") || (search_input && search_input !== "")
      ? true
      : false;
  const [showAccountPopUp, setShowAccountPopUp] = useState(0);
  const [showCartPage, setShowCartPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(
    subCatLength > 5 ? 5 : subCatLength
  );
  const [wrapperClass, setWrapperClass] = useState("");
  const [hideHeader, setHideHeader] = useState(false);
  const [menuHeight, setMenuHeight] = useState(false);
  const [applyFilters, setApplyFilters] = useState(filters);
  const [applySearch, setApplySearch] = useState(search_input);
  const [selectSubCat, setSelectSubCat] = useState(0);
  const [showFilterCon, setShowFilterCon] = useState(false);

  // infinite scroller
  const [hasMore, setHasMore] = useState(true);
  const [sliceHasMore, setSliceHasMore] = useState(3);
  const [hasMoreChanged, setHasMoreChanged] = useState(false);
  const [scrollerItems, setScrollerItems] = useState(
    table_detail?.SubCategory?.slice(0, sliceHasMore)
  );
  const searchInputContainer = useRef(null);
  const history = useHistory();
  let { id } = useParams();
  console.log("search_input", search_input);
  const prevHasMoreChanged = usePrevious(hasMoreChanged);

  useEffect(() => {}, [cart_detail]);

  useEffect(() => {
    if (selected_item_detail?.id !== undefined) {
      if (
        document.getElementById(`element_item_id_${selected_item_detail?.id}`)
      ) {
        document
          .getElementById(`element_item_id_${selected_item_detail?.id}`)
          .scrollIntoView({
            behavior: "auto",
          });
      }
    }

    Events.scrollEvent.register("begin", function (to, element) {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function (to, element) {
      console.log("end", arguments);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  // useEffect(() => {
  //   if (hasMoreChanged !== prevHasMoreChanged) {
  //     const newSubItem = table_detail?.SubCategory?.[selectSubCat];
  //     if (newSubItem) {
  //       const subItemEl = document.getElementById(`sub_id_${newSubItem.id}`);
  //       if (subItemEl) {
  //         subItemEl.click();
  //       }
  //     }
  //   }
  // }, [scrollerItems]);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        isHeaderAllUp === true
      ) {
        setWrapperClass("");
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setWrapperClass("scroll-active");
        setHideHeader(true);
      }
    },
    ...config,
  });

  const fetchMoreData = () => {
    if (scrollerItems.length === subCategoryLength) {
      setHasMore(false);
      return;
    }
    setSliceHasMore(sliceHasMore + 3);
    setScrollerItems(table_detail?.SubCategory?.slice(0, sliceHasMore + 3));
  };

  function checkItemHasTag(item) {
    let res = filters && filters !== "" ? false : true;
    const filterArr = filters && filters !== "" ? filters.split(",") : [];
    const applyFilterArr =
      filterArr.length > 0 && filter_tag_detail && filter_tag_detail !== ""
        ? filter_tag_detail.filter((item) =>
            filterArr.includes(item.id.toString())
          )
        : [];
    const filterSymbols =
      applyFilterArr.length > 0 ? pluckArray(applyFilterArr, "symbol") : [];
    if (item?.allergy_tags?.length > 0 && filterSymbols.length > 0) {
      const allergies = item.allergy_tags;
      for (let i = 0; i < allergies.length; i++) {
        const element = allergies[i];
        if (filterSymbols.includes(element.toString())) {
          res = true;
          break;
        }
      }
    }
    return res;
  }

  function checkItemHasMatchingModifiers(item) {
    let res = true;
    if (item && search_input && search_input !== "") {
      res = false;
      const searchInputText = search_input.toLowerCase();
      if (item?.pos_name.toLowerCase().indexOf(searchInputText) > -1) {
        res = true;
      } else if (
        item?.PosMenuitemsModifier &&
        item?.PosMenuitemsModifier.length > 0 &&
        item?.PosMenuitemsModifier.findIndex(
          (p) =>
            p?.Recipe?.recipe_name?.toLowerCase().indexOf(searchInputText) >
              -1 ||
            p?.Ingredient?.ingredient_name
              ?.toLowerCase()
              .indexOf(searchInputText) > -1
        ) > -1
      ) {
        res = true;
      }
    }
    return res;
  }

  function checkItemHasMatch(subCat, item) {
    let res = true;
    if (filters && filters !== "" && search_input && search_input !== "") {
      res = checkItemHasMatchingModifiers(item) && checkItemHasTag(item);
      if (res) console.log("item:", item?.pos_name, res);
    } else if (filters && filters !== "") {
      res = checkItemHasTag(item);
    } else if (search_input && search_input !== "") {
      res =
        checkItemHasMatchingModifiers(item) ||
        subCat?.name.toLowerCase().indexOf(search_input.toLowerCase()) > -1;
    }
    return res;
  }

  function filterSubCatTableDetails(subCat) {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      table_detail.Merchant &&
      table_detail.SubCategory &&
      table_detail.SubCategory.length > 0
    ) {
      return (
        subCat?.MenuItem.findIndex((p) => checkItemHasMatch(subCat, p)) > -1 ||
        (search_input &&
          search_input !== "" &&
          !(filters && filters !== "") &&
          subCat?.name.toLowerCase().indexOf(search_input.toLowerCase()) > -1)
      );
    }
    return true;
  }

  function filterItemTableDetails(subCat, item) {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      table_detail.Merchant &&
      table_detail.SubCategory &&
      table_detail.SubCategory.length > 0
    ) {
      return checkItemHasMatch(subCat, item);
    }
    return true;
  }

  const toggleCartPopUp = useCallback(
    (event) => {
      // event.preventDefault();
      setShowCartPage(!showCartPage);
    },
    [showCartPage]
  );

  console.log("id", id);

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;

    // if (isFilterOrSearchApplied) {
    //   setLastScrollHeight([]);
    //   setPageNumber(subCatLength > 5 ? 5 : subCatLength);
    // } else {
    //   if (lastScrollHeight.indexOf(headerPageScrollHeight) === -1) {
    //     setLastScrollHeight([...lastScrollHeight, headerPageScrollHeight]);
    //   }
    // }

    // console.log(
    //   "Check scrolll:",
    //   lastScrollTop,
    //   headerPageOffset,
    //   headerPageClientHeight,
    //   headerPageScrollHeight
    // );

    // if (headerPageOffset > lastScrollTop) {
    //   // down scroll
    //   const showNextPage =
    //     headerPageOffset + headerPageClientHeight >=
    //     headerPageScrollHeight - 20;
    //   if (table_detail?.SubCategory && showNextPage) {
    //     if (pageNumber + 5 < table_detail?.SubCategory.length) {
    //       setPageNumber(pageNumber + 5);
    //     } else {
    //   setPageNumber(table_detail?.SubCategory.length);
    //     }
    //   }
    // } else {
    //   // up scroll
    //   if (
    //     pageNumber > 5 &&
    //     lastScrollHeight.length > 1 &&
    //     headerPageOffset + headerPageClientHeight <
    //       lastScrollHeight[lastScrollHeight.length - 2]
    //   ) {
    //     if (table_detail?.SubCategory.length < 5) {
    //       setPageNumber(table_detail?.SubCategory.length);
    //     } else {
    //       setPageNumber(pageNumber - 5);
    //     }
    //  setLastScrollHeight(
    //       lastScrollHeight.slice(0, lastScrollHeight.length - 2)
    //     );
    //   }
    // }

    if (
      headerPageOffset &&
      headerPageOffset > 50 &&
      headerPageScrollHeight > headerPageClientHeight - 168 &&
      hideHeader === false
    ) {
      setHideHeader(true);
      setWrapperClass("scroll_active");
    } else if (headerPageOffset === 0 && hideHeader === true) {
      setHideHeader(false);
      setWrapperClass("");
    }
    // if (isFilterOrSearchApplied) {
    //   setLastScrollTop(0);
    // } else {
    //   setLastScrollTop(headerPageOffset);
    // }
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  if (
    loading === false &&
    (show_table_not_found_modal === false || isTableDataLoaded === false) &&
    (table_detail === null || table_detail === undefined)
  ) {
    getTableDetail(id, deviceid);
  } else if (
    loading === false &&
    (isTableDataLoaded === false ||
      (table_detail?.PosTableMaster?.qr_code_id &&
        table_detail?.PosTableMaster?.qr_code_id !== id))
  ) {
    emptyTableDetail();
  }
  // redirect login if not
  if (
    user_detail === null ||
    user_detail === undefined ||
    show_table_not_found_modal === true
  ) {
    history.push("/login");
  }

  function getBasketTotalAmount() {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      for (let i = 0; i < cart_detail.length; i++) {
        const item = cart_detail[i];
        const amount = parseFloat(item.total ?? 0);
        res += amount;
      }
    }
    return (
      <>
        <small>{`${table_detail?.currency ?? ""}`}</small>
        {res.toFixed(2)}
      </>
    );
  }
  function getBasketTotalQuantity() {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      res = cart_detail.reduce(function (sum, current) {
        return sum + parseInt(current.quantity);
      }, 0);
    }
    return <div className="items-count"> {res} </div>;
  }

  function getItemTotalQuantity(item) {
    let res = 0;
    const cartItemKey =
      cart_detail && cart_detail.length > 0 && item?.id
        ? cart_detail.findIndex((p) => p.id == item.id)
        : -1;
    if (cartItemKey !== -1) {
      res = cart_detail
        .filter((cartItem) => cartItem.id == item.id)
        .reduce(function (sum, current) {
          return sum + parseInt(current.quantity);
        }, 0);
    }
    return res > 0 ? <span className="txt-green">x{res}</span> : null;
  }

  function getItemTags(item) {
    let res = null;
    if (item?.is_popular && item?.is_popular === 1) {
      res = <span className="tag">Popular</span>;
    } else if (item?.is_trending && item?.is_trending === 1) {
      res = <span className="tag blue">Trending</span>;
    }
    return res;
  }

  function addFilter(id) {
    if (id !== undefined) {
      id = id.toString();
      let applyFiltersArr = applyFilters === "" ? [] : applyFilters.split(",");
      const checkIndex = applyFiltersArr.findIndex((p) => p == id);
      if (checkIndex === -1) {
        applyFiltersArr.push(id);
      } else {
        applyFiltersArr.splice(checkIndex, 1);
      }
      setApplyFilters(applyFiltersArr.join());
    }
  }

  function countActiveFilter() {
    let res = null;
    let applyFiltersArr = filters === "" ? [] : filters.split(",");
    const checkIndex = applyFiltersArr.findIndex((p) => p == id);
    if (applyFiltersArr.length > 0) {
      res = <span className="count">{applyFiltersArr.length}</span>;
    }
    return res;
  }

  function checkActiveFilter(id) {
    let res = false;
    if (id !== undefined) {
      id = id.toString();
      let applyFiltersArr = applyFilters === "" ? [] : applyFilters.split(",");
      const checkIndex = applyFiltersArr.findIndex((p) => p == id);
      if (checkIndex != -1) {
        res = true;
      }
    }
    return res;
  }

  function getFilterModalTags() {
    let res = null;
    if (filter_tag_detail && filter_tag_detail.length > 0) {
      res = (
        <ul>
          {filter_tag_detail.map(function (tag, index) {
            const checkActive = checkActiveFilter(tag.id);
            let style = {
              border: `2px solid ${tag?.color ?? "#000"}`,
              color: `${tag?.color ?? "#000"}`,
            };
            if (checkActive) {
              style.background = tag?.color ?? "";
              style.color = "#fff";
            }
            return (
              <li style={style} key={index} onClick={() => addFilter(tag.id)}>
                {tag?.name ?? ""}
              </li>
            );
          })}
        </ul>
      );
    }
    return res;
  }

  function getItemFilterTags(item) {
    let res = null;
    if (item?.allergy_tags && item?.allergy_tags.length > 0) {
      res = (
        <ul>
          {item?.allergy_tags.map(function (tag, index) {
            const filterTagKey =
              filter_tag_detail && filter_tag_detail.length > 0
                ? filter_tag_detail.findIndex((p) => p.symbol == tag)
                : null;
            const filterTag =
              filterTagKey !== null && filterTagKey !== undefined
                ? filter_tag_detail[filterTagKey]
                : null;
            const style =
              filterTag?.color && filterTag?.color !== ""
                ? { background: filterTag?.color ?? "" }
                : {};
            const className =
              filterTag?.color && filterTag?.color !== "" ? "" : "gray";
            return (
              <li style={style} className={className} key={index}>
                {filterTag?.symbol ?? ""}
              </li>
            );
          })}
        </ul>
      );
    }
    return res;
  }

  function getHomePage() {
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <div
          className={`wrapper custom_pb ${hideHeader ? "scroll_active" : ""}`}
        >
          <Motion
            defaultStyle={{ x: 270 }}
            style={{
              x: spring(hideHeader === false ? 270 : 0, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => (
              <header
                style={{
                  height: `${x}px`,
                }}
              >
                <div className="container">
                  <div className="row mx-0 profile-row d-none">
                    <div className="circle-img col-2">
                      <figure className="">
                        <ImageTag src={table_detail?.Merchant?.logo} alt="" />
                      </figure>
                    </div>
                    <div className="naming-sec col-6">
                      <h4> {table_detail?.Merchant?.account_name} </h4>
                      <p className="md-txt h5">
                        {" "}
                        Table #{" "}
                        <span className="h3">
                          {" "}
                          {table_detail?.PosTableMaster?.table_number}{" "}
                        </span>
                      </p>
                    </div>
                    <div className="col-4 notify">
                      <a href="#_" className="notification">
                        {/* <span className="counts"> 10 </span> */}
                      </a>
                      <a href="#_" className="profile">
                        {" "}
                      </a>
                    </div>
                  </div>

                  <div className="row mx-0 welcome-sec">
                    <div className="circle-img-lg">
                      <figure className="logo">
                        <ImageTag src={table_detail?.Merchant?.logo} alt="" />
                      </figure>
                    </div>
                    <div className="right_col">
                      <div className="notify">
                        <a href="#_" className="notification">
                          {/* <span className="counts"> 10 </span> */}
                        </a>
                        <a
                          href="#"
                          onClick={() => setShowAccountPopUp(1)}
                          className="profile"
                        >
                          {" "}
                        </a>
                      </div>

                      <div className="naming-sec">
                        <p> Welcome to </p>
                        <h3> {table_detail?.Merchant?.account_name} </h3>
                        <p className="powered_text">
                          {" "}
                          Powered By{" "}
                          <span className="powered_logo">
                            {" "}
                            <ImageTag src={ordrrLogoNew} />
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="rating_row">
                    <p>Tap to rate, slide for half star</p>
                    <div className="rating_outer">
                      <RatingInput />
                    </div>
                  </div>

                  <div className="row mx-0 filter_row">
                    <span className="filter_icon">
                      <ImageTag
                        src={filterImg}
                        alt="filter"
                        onClick={() => {
                          setShowFilterCon(!showFilterCon);
                          setApplyFilters(filters);
                        }}
                      />{" "}
                      {countActiveFilter()}
                    </span>
                    <input
                      type="search"
                      className="search-bar input-text"
                      placeholder="Dishes, Ingredients, or Categories"
                      value={search_text}
                      ref={searchInputContainer}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchInputContainer?.current.blur();
                          setApplySearch(e.target.value);
                          getTableDetailByFilter(
                            id,
                            applyFilters,
                            e?.target?.value
                          );
                        }
                      }}
                      onChange={(e) => {
                        if (e?.target?.value === "") {
                          searchInputContainer?.current.blur();
                          setApplySearch("");
                          getTableDetailByFilter(id, applyFilters, "");
                        } else {
                          setApplySearch(e?.target?.value);
                          updateSearchText(e?.target?.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </header>
            )}
          </Motion>
          <section className="middle-sec ">
            <div className="filter_row menus_row">
              <span className="filter_icon">
                <ImageTag
                  src={filterImg}
                  alt="filter"
                  onClick={() => {
                    setShowFilterCon(!showFilterCon);
                    setApplyFilters(filters);
                  }}
                />{" "}
                {countActiveFilter()}
              </span>

              <div className="menu-row row mx-0">
                {/* <Link
                  className={`col link-box`}
                  activeClass="active"
                  to={"recommended_section"}
                  id={"recommended_section"}
                  containerId="menu-sections"
                  spy={true}
                  smooth={true}
                  // delay={100}
                  offset={0}
                  duration={300}
                  onSetActive={(to, element) => handleSetActive(to, element)}
                  // onClick={() => {
                  //   setSelectSubCat(subIndex);
                  //   // const roundNum = Math.ceil((subIndex + 1) / 5);
                  //   // setPageNumber(roundNum * 5);
                  //   // setLastScrollHeight([]);
                  // }}
                >
                  <Motion
                    defaultStyle={{ h: 85 }}
                    style={{
                      h: spring(hideHeader === false ? 85 : 35, {
                        precision: 1,
                        stiffness: 300,
                        damping: 44,
                      }),
                    }}
                  >
                    {({ h }) => (
                      <a
                        // href={
                        //   subCat?.name ? "#sub_id_" + subCat?.id : "#"
                        // }

                        className="blue"
                        style={{
                          height: `${h}px`,
                          fontSize: `${hideHeader ? "14px" : "11px"}`,
                          // WebkitTransform: `translate3d(${h}px, 0, 0)`,
                          // transform: `translate3d(${h}px, 0, 0)`,
                        }}
                      >
                        {"Recommended"}
                      </a>
                    )}
                  </Motion>
                </Link> */}
                {table_detail?.SubCategory &&
                  table_detail?.SubCategory.filter((SubCat) =>
                    filterSubCatTableDetails(SubCat)
                  ).map(function (subCat, subIndex) {
                    return (
                      <Link
                        className={`col link-box`}
                        activeClass="active"
                        to={subCat?.name ? "sub_id_" + subCat?.id : "#"}
                        id={subCat?.name ? "sub_id_" + subCat?.id : "#"}
                        containerId="menu-sections"
                        spy={true}
                        smooth={true}
                        isDynamic={true}
                        delay={100}
                        offset={0}
                        scrolloffset={10}
                        duration={300}
                        onSetActive={(to, element) => {
                          // console.log("chekckkkk", to);
                          // alert(to);
                          handleSetActive(to, element);
                        }}
                        key={subIndex}
                        onClick={(e) => {
                          setSelectSubCat(subIndex);

                          // infinite scroller
                          // if (subIndex > sliceHasMore) {
                          //   const sliceMoreIndex =
                          //     subIndex >= subCategoryLength - 2
                          //       ? table_detail?.SubCategory?.length -
                          //         1
                          //       : subIndex + 2;
                          //   // console.log("chekkkkkkkk=>>>", sliceMoreIndex);
                          //   setSliceHasMore(sliceMoreIndex);
                          //   console.log("innnnnnnnnnnn");
                          //   setScrollerItems(
                          //     table_detail?.SubCategory?.slice(
                          //       0,
                          //       sliceMoreIndex
                          //     )
                          //   );
                          //   console.log("outtttttttt");

                          //   setHasMoreChanged(!hasMoreChanged);
                          //   // setTimeout(() => {
                          //   //   e.target.click();
                          //   // }, 100);
                          // }
                          // infinite scroller END

                          // const roundNum = Math.ceil((subIndex + 1) / 5);
                          // setPageNumber(roundNum * 5);
                          // setLastScrollHeight([]);
                        }}
                      >
                        <Motion
                          defaultStyle={{ h: 85 }}
                          style={{
                            h: spring(hideHeader === false ? 85 : 35, {
                              precision: 1,
                              stiffness: 300,
                              damping: 44,
                            }),
                          }}
                        >
                          {({ h }) => (
                            <a
                              // href={
                              //   subCat?.name ? "#sub_id_" + subCat?.id : "#"
                              // }

                              className="blue"
                              style={{
                                height: `${h}px`,
                                // WebkitTransform: `translate3d(${h}px, 0, 0)`,
                                // transform: `translate3d(${h}px, 0, 0)`,
                              }}
                            >
                              {subCat?.name}
                            </a>
                          )}
                        </Motion>
                      </Link>
                    );
                  })}
              </div>
            </div>

            <div
              className="menu-sections"
              id="menu-sections"
              onScroll={scrolledElement}
              {...handlers}
            >
              {/* <InfiniteScroll
                dataLength={scrollerItems.length}
                next={() => fetchMoreData()}
                // style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
                // inverse={true}
                hasMore={hasMore}
                // loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
                scrollableTarget="menu-sections"
              > */}
              {/* {card_detail?.CardInfo?.CardNo !== "" && (
                <Element name={"recommended_section"}>
                  <div className="recommended_section">
                    <h3 className="title">Recommended for you</h3>
                    <div className="recommended_slider">
                      <div className="swiper-slide">
                        <div className="box">
                          <div className="title_row">
                            <div className="item_title">
                              <span>x2</span> Buffalo Wings Truffle Fries
                            </div>
                            <div className="price">
                              <small>$</small>42.50
                            </div>
                          </div>

                          <div className="date_row">
                            <p className="date">Friday, April 2nd</p>
                            <p className="reorder">
                              <ImageTag src={reorder} />
                              Reorder
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="swiper-slide">
                        <div className="box">
                          <div className="title_row">
                            <div className="item_title">
                              <span>x2</span> Buffalo Wings Truffle Fries
                            </div>
                            <div className="price">
                              <small>$</small>42.50
                            </div>
                          </div>

                          <div className="date_row">
                            <p className="date">Friday, April 2nd</p>
                            <p className="reorder">
                              <ImageTag src={reorder} />
                              Reorder
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="swiper-slide">
                        <div className="box">
                          <div className="title_row">
                            <div className="item_title">
                              <span>x2</span> Buffalo Wings Truffle Fries
                            </div>
                            <div className="price">
                              <small>$</small>42.50
                            </div>
                          </div>

                          <div className="date_row">
                            <p className="date">Friday, April 2nd</p>
                            <p className="reorder">
                              <ImageTag src={reorder} />
                              Reorder
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="swiper-slide">
                        <div className="box">
                          <div className="title_row">
                            <div className="item_title">
                              <span>x2</span> Buffalo Wings Truffle Fries
                            </div>
                            <div className="price">
                              <small>$</small>42.50
                            </div>
                          </div>

                          <div className="date_row">
                            <p className="date">Friday, April 2nd</p>
                            <p className="reorder">
                              <ImageTag src={reorder} />
                              Reorder
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="swiper-slide">
                        <div className="box">
                          <div className="title_row">
                            <div className="item_title">
                              <span>x2</span> Buffalo Wings Truffle Fries
                            </div>
                            <div className="price">
                              <small>$</small>42.50
                            </div>
                          </div>

                          <div className="date_row">
                            <p className="date">Friday, April 2nd</p>
                            <p className="reorder">
                              <ImageTag src={reorder} />
                              Reorder
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Element>
              )} */}
              {/* .slice(
                  0,
                  isFilterOrSearchApplied ? subCatLength : pageNumber
                ) */}
              {table_detail?.SubCategory.filter((SubCat) =>
                filterSubCatTableDetails(SubCat)
              ).map(function (subCat, index) {
                const menuItem = subCat?.MenuItem ?? undefined;
                return (
                  <Element
                    key={index}
                    className={`${
                      index === table_detail?.SubCategory?.length - 1
                        ? "last-menu-items"
                        : ""
                    }`}
                    name={subCat?.name ? "sub_id_" + subCat?.id : "null"}
                  >
                    <h3 className="title"> {subCat?.name}</h3>
                    {menuItem.length > 0 &&
                      menuItem
                        .filter((item) => filterItemTableDetails(subCat, item))
                        .map(function (item, itemKey) {
                          const cartItemKey =
                            cart_detail && cart_detail.length > 0 && item?.id
                              ? cart_detail.findIndex((p) => p.id == item.id)
                              : -1;
                          const checkAvailable = checkItemAvailable(
                            item,
                            table_detail?.Merchant?.timezone_offset ?? "00:00"
                          );
                          return (
                            <div
                              className={`menu-box ${
                                cartItemKey !== -1 ? " active" : ""
                              } ${checkAvailable === false ? " disabled" : ""}`}
                              key={itemKey}
                              id={`element_${
                                item?.id !== undefined
                                  ? "item_id_" + item?.id
                                  : "null"
                              }`}
                            >
                              {getItemTags(item)}
                              <div
                                onClick={() => {
                                  if (checkAvailable) {
                                    getSelectedItemDetail(item);
                                    emptyEditedItemDetail();
                                    if (item?.menu_item_type == "bundled")
                                      history.push("/bundle-add-item");
                                    else history.push("/add-item");
                                  }
                                }}
                                className="row mx-0 align-items-center"
                              >
                                <figure className="col-3 menu-img">
                                  <ImageTag
                                    src={item?.image}
                                    defaultimage={table_detail?.Merchant?.logo}
                                    removeImage={true}
                                  />
                                </figure>
                                <div className="col-9 menu-content">
                                  <h4>
                                    {getItemTotalQuantity(item)}{" "}
                                    {item?.pos_name}
                                  </h4>
                                  <p> {item?.description}</p>
                                  <div className="label_row">
                                    <p className="pricing">
                                      {" "}
                                      <small>{`${
                                        table_detail?.currency ?? ""
                                      }`}</small>
                                      {item?.dinein_price?.DineIn
                                        ? parseFloat(
                                            item?.dinein_price?.DineIn
                                          ).toFixed(2)
                                        : "0.00"}
                                    </p>
                                    {getItemFilterTags(item)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                  </Element>
                );
              })}
              {/* </InfiniteScroll> */}
            </div>
          </section>
          <section
            className={`bottom-sec ${
              cart_detail?.length <= 0 ? "disabled" : ""
            }`}
          >
            <div
              onClick={() => {
                // history.push("/cart")
                if (cart_detail?.length > 0) {
                  setShowCartPage(true);
                }
              }}
              className="green-sec"
            >
              <div className="col-3">{getBasketTotalQuantity()}</div>
              <div className="col-5 text-center">
                <strong> View Basket </strong>
              </div>
              <div className="col-4 px-0">
                <div className="total-price">
                  <strong> {getBasketTotalAmount()}</strong> Total{" "}
                </div>
              </div>
            </div>
          </section>
          {/* add show class  */}
          <div className={`filter_modal ${showFilterCon ? "show" : ""}`}>
            <div className="modal_header">
              <div className="filter_icon">
                <ImageTag src={filterImg} />
                {countActiveFilter()}
              </div>
              <h4>Filters</h4>
              <div className="close">
                <ImageTag
                  src={close_dark}
                  onClick={() => {
                    setApplyFilters(filters);
                    setShowFilterCon(false);
                  }}
                />
              </div>
            </div>
            <div className="modal_body">
              <h5 className="title">By Tags</h5>
              {getFilterModalTags()}
              <button
                className="apply_btn"
                disabled={filters === applyFilters}
                onClick={() => {
                  setShowFilterCon(false);
                  getTableDetailByFilter(id, applyFilters, applySearch);
                }}
              >
                Apply
              </button>
            </div>
          </div>
          {/* account modal */}
          {showCartPage === false && (
            <AccountPopup
              show={showAccountPopUp}
              onCancel={() => setShowAccountPopUp(0)}
            />
          )}
          <Modal
            show={show_table_already_inuse_modal}
            title="There is another device currently ordering for this table, do you wish to create a separate order in addition to that device’s order?"
            onCancel={() => {
              emptyTableDetail();
              changeTableNotFound();
            }}
            OnSubmit={() => emptyChangeTableAlreadyInuse()}
            cancelBtnText="No"
            submitBtnText="Yes"
          />
          <Motion
            defaultStyle={{ x: 100 }}
            style={{
              x: spring(showCartPage === true ? 0 : 100, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => {
              return (
                <Cart
                  style={{
                    right: `-${x}%`,
                  }}
                  setShowCartPage={toggleCartPopUp}
                />
              );
            }}
          </Motion>
        </div>
      );
    } else if (loading === false) {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <PleaseWait />
        </div>
      );
    }
  }

  return <>{getHomePage()}</>;
}

const mapStateToProps = (state) => ({
  selected_item_detail: state.tableDetailReducer.selected_item_detail,
  table_detail: state.tableDetailReducer.table_detail,
  show_table_already_inuse_modal:
    state.tableDetailReducer.show_table_already_inuse_modal,
  show_table_not_found_modal:
    state.tableDetailReducer.show_table_not_found_modal,
  filter_tag_detail: state.tableDetailReducer.filter_tag_detail,
  filters: state.tableDetailReducer.filters,
  search_input: state.tableDetailReducer.search_input,
  search_text: state.tableDetailReducer.search_text,
  cart_detail: state.cartDetailReducer.cart_detail,
  user_detail: state.authDetailReducer.user_detail,
  card_detail: state.authDetailReducer.card_detail,
  loading: state.loaderReducer.loading,
});

const mapDispatchToProps = {
  getTableDetail: getTableDetail,
  emptyTableDetail: emptyTableDetail,
  getTableDetailByFilter: getTableDetailByFilter,
  getSelectedItemDetail: getSelectedItemDetail,
  emptyEditedItemDetail: emptyEditedItemDetail,
  updateSearchText: updateSearchText,
  emptyChangeTableAlreadyInuse: emptyChangeTableAlreadyInuse,
  changeTableNotFound: changeTableNotFound,
};

Home = connect(mapStateToProps, mapDispatchToProps)(Home);
export default Home;
