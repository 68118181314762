import React, { useRef } from "react";
import { useState } from "react";
import Collapsible from "react-collapsible";
import {
  parseStringToDate,
  parseStringToFloat,
  getUnixDateFormat,
} from "../../helpers";
import { image2Icon, userImgIcon } from "../../Assets";

function OrderHistoryDetails({ order }) {
  const [open, setOpen] = useState(false);

  function getBundleModifiers(bundle) {
    let res = null;
    if (bundle?.PosOrderItemDetail?.length > 0) {
      const modifierArr = bundle?.PosOrderItemDetail?.map(
        (modifier, modIndex) => {
          return (
            <div
              className="col-12 d-flex justify-content-between pt-3 px-0"
              key={modIndex}
            >
              <p className="h6 pl-5 font-italic"> {modifier?.modifier_name}</p>
              {parseStringToFloat(modifier?.unit_price) > 0 && (
                <p className="h6">
                  ({modifier?.action == "remove" ? "-" : ""}
                  {parseStringToFloat(modifier?.unit_price)})
                </p>
              )}
            </div>
          );
        }
      );
      res = <>{modifierArr}</>;
    }
    return res;
  }

  function getModifiers(item) {
    let res = null;
    if (item?.is_bundle == false && item?.PosOrderItemDetail?.length > 0) {
      const modifierArr = item?.PosOrderItemDetail?.map(
        (modifier, modIndex) => {
          return (
            <div
              className="col-12 d-flex justify-content-between pt-3 px-0"
              key={modIndex}
            >
              <p className="h6 pl-2 font-italic"> {modifier?.modifier_name}</p>
              {parseStringToFloat(modifier?.unit_price) > 0 && (
                <p className="h6">
                  ({modifier?.action == "remove" ? "-" : ""}
                  {parseStringToFloat(modifier?.unit_price)})
                </p>
              )}
            </div>
          );
        }
      );
      res = <>{modifierArr}</>;
    }
    if (item?.is_bundle == true && item?.PosOrderBundleItem?.length > 0) {
      const bundleArr = item?.PosOrderBundleItem?.map((bundle, bunIndex) => {
        return (
          <>
            <div
              className="col-12 d-flex justify-content-between pt-3 px-0"
              key={bunIndex}
            >
              <p className="h6 pl-2 font-italic">
                {parseInt(bundle?.quantity ?? 0)} x {bundle?.menu_item_name}
              </p>
              {/* {parseStringToFloat(bundle?.menu_item_price) > 0 && (
                <p className="h6">
                  ({parseStringToFloat(bundle?.menu_item_price)})
                </p>
              )} */}
            </div>
            {getBundleModifiers(bundle)}
          </>
        );
      });
      res = bundleArr;
    }
    return res;
  }

  function getMoreItems() {
    let res = null;
    if (order?.PosOrderItem?.length > 0) {
      res = order?.PosOrderItem?.map((item, index) => {
        return (
          <>
            <div
              className="col-12 d-flex justify-content-between pt-3 px-0"
              key={index}
            >
              <p className="h6">
                <strong>
                  {" "}
                  {parseInt(item?.menu_item_quantity ?? 0)} x{" "}
                  {item?.menu_item_name} @{" "}
                  {parseStringToFloat(item?.menu_item_price)}
                </strong>
              </p>
              <p className="h6">
                <strong>{parseStringToFloat(item?.total_price)} </strong>{" "}
              </p>
            </div>
            {getModifiers(item)}
          </>
        );
      });
    }
    return res;
  }
  return (
    <div className="history_details">
      <div className="row mx-0 justify-content-start">
        <div className="circle-img col-2 mx-0">
          <figure className="">
            <img src={userImgIcon} alt="" />
          </figure>
        </div>
        <div className="naming-sec col-6">
          <h4> {order?.TransactOutletName ?? ""} </h4>
          <p>
            {" "}
            Visit Date:{" "}
            {order?.TransactDate !== undefined
              ? getUnixDateFormat(order?.TransactDate, "D/MM/YY")
              : "N/A"}{" "}
          </p>
          <p> Order ID: {order?.ReceiptNo ?? "N/A"} </p>
        </div>
        <div className="col-4 px-0">
          <h2 className="pricing">
            {" "}
            <small>$</small>
            {parseStringToFloat(order?.NettSpent ?? 0)}{" "}
          </h2>
          <button className="more-btn" onClick={() => setOpen(!open)}>
            {" "}
            More{" "}
          </button>
        </div>
      </div>
      <Collapsible open={open}>
        <div className="more-info">
          {/* {getMoreItems()} */}
          <div className="bill-details">
            <div className="row mx-0 justify-content-end">
              <div className="col-8 px-0">
                <ul className="px-0">
                  <li>
                    <label> Sub-Total </label>
                    <h4>
                      <small> $</small>
                      {parseStringToFloat(order?.PosOrder?.gross_sale)}{" "}
                    </h4>
                  </li>
                  <li>
                    <label> Discounts </label>
                    <h4>
                      (<small>$</small>
                      {parseStringToFloat(order?.PosOrder?.discount)}){" "}
                    </h4>
                  </li>
                  <li>
                    <label> Service Charge </label>
                    <h4>
                      <small> $</small>
                      {parseStringToFloat(order?.PosOrder?.service_charge)}{" "}
                    </h4>
                  </li>
                  <li>
                    <label> Tax </label>
                    <h4>
                      <small> $</small>
                      {parseStringToFloat(order?.PosOrder?.sales_tax)}{" "}
                    </h4>
                  </li>
                  <li className="total">
                    <label> Total </label>
                    <h4>
                      <small> $</small>
                      {parseStringToFloat(order?.PosOrder?.total)}{" "}
                    </h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
  );
}

export default OrderHistoryDetails;
