import {
  SEND_OTP_DETAIL_RECEIVED,
  EMPTY_OTP_DETAIL,
  USER_DETAIL_RECEIVED,
  EMPTY_USER_DETAIL,
  EMAIL_INPUT_RECEIVED,
  OTP_INPUT_RECEIVED,
  SHOW_OTP_INPUT_TOGGLE,
  CARD_DETAIL_RECEIVED,
  EMPTY_CARD_DETAIL,
  MEMBER_DETAIL_RECEIVED,
  EMPTY_MEMBER_DETAIL,
} from "../actions/types";

const initialState = {
  otp_detail: null,
  user_detail: null,
  card_detail: null,
  member_detail: null,
  email_input: "",
  otp_input: "",
  show_otp_input: false,
};

const authDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_OTP_DETAIL_RECEIVED:
      return { ...state, otp_detail: action.payload };
    case EMPTY_OTP_DETAIL:
      return { ...state, otp_detail: null };
    case USER_DETAIL_RECEIVED:
      return {
        ...state,
        user_detail: action.payload,
        email_input: "",
        otp_input: "",
        show_otp_input: false,
      };
    case EMPTY_USER_DETAIL:
      return { ...state, user_detail: null };
    case EMAIL_INPUT_RECEIVED:
      return { ...state, email_input: action.input };
    case OTP_INPUT_RECEIVED:
      return { ...state, otp_input: action.input };
    case SHOW_OTP_INPUT_TOGGLE:
      return { ...state, show_otp_input: action.input };
    case CARD_DETAIL_RECEIVED:
      return {
        ...state,
        card_detail: action.payload,
      };
    case EMPTY_CARD_DETAIL:
      return { ...state, card_detail: null };
    case MEMBER_DETAIL_RECEIVED:
      return {
        ...state,
        member_detail: action.payload,
      };
    case EMPTY_MEMBER_DETAIL:
      return { ...state, member_detail: null };
    default:
      return state;
  }
};
export default authDetailReducer;
