import React from "react";
import {
  capitalizeFirstLetter,
  nFormatter,
  getUnixDateFormat,
} from "../../helpers";
import { image2Icon, userImgIcon } from "../../Assets";
import { connect, useSelector, useDispatch } from "react-redux";

function LoyalityAccount(params) {
  const cardDetail = useSelector(
    (state) => state?.authDetailReducer?.card_detail
  );
  return (
    <div>
      <section className="info-sec">
        <div className="account-info">
          <h4 className="title"> Account Info </h4>
          <div className="info d-flex align-items-end">
            <div className="col-3 border-right my-2">
              <p> Active Vouchers </p>
              <h4>
                {" "}
                {cardDetail?.TotalActiveVoucherCount
                  ? parseInt(cardDetail?.TotalActiveVoucherCount)
                  : 0}{" "}
              </h4>
            </div>
            <div className="col-3 border-right my-2">
              <p> Points Balance </p>
              <h4>
                {" "}
                {cardDetail?.CardInfo?.TotalPointsBAL
                  ? nFormatter(cardDetail?.CardInfo?.TotalPointsBAL)
                  : 0}{" "}
              </h4>
            </div>
            <div className="col-3 border-right my-2">
              <p> Points Used </p>
              <h4>
                {" "}
                {cardDetail?.CardInfo?.TotalPointsBAL
                  ? nFormatter(cardDetail?.CardInfo?.TotalPointsBAL)
                  : 0}{" "}
              </h4>
            </div>
            <div className="col-3 my-2 visits">
              <p> # of Visits </p>
              <h4> {cardDetail?.MembershipInfo?.TotalVisits ?? 0} </h4>
            </div>
          </div>

          <div className="info-details row mx-0">
            <div className="col-6">
              <label> Member Tier </label>
              <div className="tag green">
                {cardDetail?.CardInfo?.TierCode ?? "N/A"}
              </div>
            </div>
            <div className="col-6">
              <label> Member Since </label>
              <p>
                {cardDetail?.CardInfo?.IssueDate
                  ? getUnixDateFormat(cardDetail?.CardInfo?.IssueDate)
                  : "N/A"}
              </p>
            </div>
            <div className="col-6">
              <label> Status </label>
              <p>
                {" "}
                {cardDetail?.CardInfo?.MembershipStatusCode
                  ? capitalizeFirstLetter(
                      cardDetail?.CardInfo?.MembershipStatusCode
                    )
                  : "N/A"}{" "}
              </p>
            </div>
            <div className="col-6">
              <label> Last Visit </label>
              <p>
                {" "}
                {cardDetail?.CardInfo?.LastVisitedDate
                  ? getUnixDateFormat(cardDetail?.CardInfo?.LastVisitedDate)
                  : "N/A"}{" "}
              </p>
            </div>
            <div className="col-6">
              <label> Regular Store </label>
              <p>
                {" "}
                {cardDetail?.MembershipInfo?.MostCycleVisitedOutletCode !== ""
                  ? cardDetail?.MembershipInfo?.MostCycleVisitedOutletCode
                  : "N/A"}{" "}
              </p>
            </div>
            <div className="col-6">
              <label> Birth Date </label>
              <p>
                {" "}
                {cardDetail?.MemberInfo?.DOB
                  ? getUnixDateFormat(cardDetail?.MemberInfo?.DOB)
                  : "N/A"}{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="fav-sec">
        <div className="fav-info">
          <h4 className="title"> Favorites </h4>
        </div>
        <div className="menu-box row">
          <figure className="col-2 menu-img py-2">
            <img src={image2Icon} />
          </figure>
          <div className="col-6 menu-content pl-2">
            <h4>Truffle Fries</h4>
            <p>
              {" "}
              Double Deep Fried Hand Cut Potatoes Covered in White Truffle Oil{" "}
            </p>
            <p className="pricing">
              {" "}
              <small>$</small>15.00{" "}
            </p>
          </div>
          <div className="col-3 px-0 offset-1">
            <a href="#_" className="square-img">
              <h5> 4 </h5>
              <p> Promos </p>
            </a>
          </div>
        </div>

        <div className="menu-box row">
          <figure className="col-2 menu-img py-2">
            <img src={image2Icon} />
          </figure>
          <div className="col-6 menu-content pl-2">
            <h4>Truffle Fries</h4>
            <p>
              {" "}
              Double Deep Fried Hand Cut Potatoes Covered in White Truffle Oil{" "}
            </p>
            <p className="pricing">
              {" "}
              <small>$</small>15.00{" "}
            </p>
          </div>
          <div className="col-3 px-0 offset-1">
            <a href="#_" className="square-img">
              <h5> 4 </h5>
              <p> Promos </p>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
export default LoyalityAccount;
