import moment from "moment";
import axios from "axios";

export function capitalizeFirstLetter(string) {
  if (string && typeof string === "string" && string !== "") {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "";
}

export function truncateString(str, n) {
  return str.length > n ? str.substr(0, n - 1) + ".." : str;
}

function replaceDateStringFrom(date) {
  let res = undefined;
  try {
    if (date && typeof date === "string" && date !== "") {
      res = date.replace("/Date(", "");
      res = res.replace(")/", "");
      res = moment(parseInt(res));
    }
  } catch (e) {}
  return res;
}

export function getUnixDateFormat(dateNum, format = "MMMM D, YYYY") {
  let res = "N/A";
  const getDate = replaceDateStringFrom(dateNum);
  if (getDate && getDate !== undefined) {
    res = getDate.format(format);
  }
  return res;
}

export function getTypeOfVoucher(type, voucher) {
  let res = false;
  let todayDate = moment();
  const fromDateObj = replaceDateStringFrom(voucher?.ValidTo);
  if (fromDateObj && fromDateObj !== undefined) {
    // console.log("todayDate:", todayDate.format("MMMM D, YYYY"));
    // console.log("fromDateObj:", fromDateObj.format("MMMM D, YYYY"));
    // console.log("cjeckkk:", fromDateObj.diff(todayDate, "days"));
    if (type == 0 && fromDateObj.diff(todayDate, "days") > 0) {
      res = true;
    } else if (
      type == 1 &&
      fromDateObj.diff(todayDate, "days") > 0 &&
      voucher?.IsRedeemable == false
    ) {
      res = true;
    } else if (type == 2 && fromDateObj.diff(todayDate, "days") < 0) {
      res = true;
    }
  }
  return res;
}

export function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

export function getModifierActionName(modifierAction) {
  let res = "";
  switch (modifierAction) {
    case "add":
      res = "Add";
      break;
    case "remove":
      res = "Remove";
      break;
    case "swap":
      res = "Swap";
      break;
    default:
      break;
  }
  return res;
}

export function getBundleItemOptionsPrice(bundleItem, currency = "") {
  let res = null;
  if (
    bundleItem?.action_price !== null &&
    bundleItem?.action_price !== "" &&
    bundleItem?.amount !== "" &&
    bundleItem?.amount !== null
  ) {
    res = (
      <span className="price-sm">
        {parseFloat(bundleItem.amount) !== 0
          ? checkNegNum(bundleItem.amount)
            ? "-"
            : "+"
          : ""}
        {`${currency}`}
        {parseFloat(bundleItem.amount) < 0
          ? makeNegToPosNum(bundleItem.amount)
          : parseFloat(bundleItem.amount).toFixed(2)}
      </span>
    );
  } else if (
    bundleItem?.unit_price !== null &&
    bundleItem?.unit_price !== "" &&
    parseFloat(bundleItem?.unit_price) > 0
  ) {
    res = (
      <span className="price-sm">
        {"+"}
        {`${currency}`}
        {parseFloat(bundleItem.unit_price).toFixed(2)}
      </span>
    );
  }
  return res;
}

export function getModifierActionSign(modifierAction) {
  let res = "";
  switch (modifierAction) {
    case "add":
      res = "+";
      break;
    case "remove":
      res = "-";
      break;
    case "swap":
      res = "+";
      break;
    default:
      break;
  }
  return res;
}

export function checkNegNum(num) {
  return parseFloat(num) < 0;
}

export function makeNegToPosNum(num) {
  return -1 * parseFloat(num);
}

export function getModifierTypeName(modifierType, modifier) {
  let res = "";
  res = modifier?.modifier_name ?? "";
  // if (modifier?.action == "size") {
  //   res = modifier?.modifier_name ?? "";
  // } else {
  //   switch (modifierType) {
  //     case "ingredient":
  //       res = `${modifier?.Ingredient?.ingredient_name ?? ""} ${
  //         modifier?.action == "swap"
  //           ? " with " + modifier?.SwapIngredient?.ingredient_name ?? ""
  //           : ""
  //       }`;
  //       break;
  //     case "recipe":
  //       res = `${modifier?.Recipe?.recipe_name ?? ""} ${
  //         modifier?.action == "swap"
  //           ? " with " + modifier?.SwapRecipe?.recipe_name ?? ""
  //           : ""
  //       }`;
  //       break;
  //     default:
  //       break;
  //   }
  // }

  return res;
}

export function setPageDetails(data = {}) {
  if (data.title) {
    document.title = data.title;
  }
  if (data.favIcon) {
    document.getElementById("fav-icon").setAttribute("href", data.favIcon);
    document
      .getElementById("apple-touch-icon")
      .setAttribute("href", data.favIcon);
  }
}

export function pluckArray(arr, key) {
  return arr.map((i) => i[key]);
}

export function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

export function checkItemExistInCart(item, cart) {
  let res = -1;
  if (cart?.length > 0) {
    for (let i = 0; i < cart.length; i++) {
      const element = cart[i];
      if (
        item?.id == element?.id &&
        element?.is_added == false &&
        JSON.stringify(item?.modifierArray) ==
          JSON.stringify(element?.modifierArray)
      ) {
        res = i;
        break;
      }
    }
  }
  return res;
}

export function checkBundleItemExistInCart(item, cart) {
  let res = -1;
  if (
    cart?.length > 0 &&
    ((item?.modifierArray && item?.modifierArray?.length > 0) ||
      (item?.BundledMenuItem && item?.BundledMenuItem?.length > 0))
  ) {
    for (let i = 0; i < cart.length; i++) {
      const element = cart[i];
      if (
        JSON.stringify(item?.modifierArray) ==
          JSON.stringify(element?.modifierArray) &&
        JSON.stringify(item?.selectedBundleItem) ==
          JSON.stringify(element?.selectedBundleItem)
      ) {
        res = i;
        break;
      }
    }
  }
  return res;
}

export function getItemById(itemId, subId, table_detail) {
  let res = null;
  if (table_detail?.SubCategory?.length > 0) {
    const subIndex = table_detail?.SubCategory?.findIndex((p) => p.id == subId);
    if (subIndex > -1) {
      const subCat = table_detail?.SubCategory?.[subIndex];
      const itemIndex = subCat?.MenuItem?.findIndex((p) => p.id == itemId);
      if (itemIndex > -1) {
        res = subCat?.MenuItem?.[itemIndex];
      }
    }
  }
  return res;
}

export function getCartSubmittedItems(orders, table_detail) {
  let items = [];
  if (orders?.menu_item && orders?.menu_item?.length > 0) {
    orders?.menu_item?.map((order) => {
      const menuItemQyt = order?.menu_item_quantity
        ? parseFloat(order?.menu_item_quantity)
        : 1;
      const quantityMultipleBy = order?.quantityMultipleBy
        ? parseFloat(order?.quantityMultipleBy)
        : 1;
      const menuItemPrice = order?.menu_item_price
        ? parseFloat(order?.menu_item_price)
        : 0;
      const menuTotalPrice = order?.doubleActualAmout
        ? parseFloat(order?.doubleActualAmout)
        : 0;
      const exactQyt = parseFloat(menuItemQyt / quantityMultipleBy);
      const menuPriceForOne = menuTotalPrice / exactQyt;
      const diffInTotalPrice = menuPriceForOne - menuItemPrice;
      // console.log("cheksadkkkkkkk=>>>exactQyt", exactQyt);
      // console.log("cheksadkkkkkkk=>>>menuPriceForOne", menuPriceForOne);
      // console.log("cheksadkkkkkkk=>>>diffInTotalPrice", diffInTotalPrice);
      // console.log("cheksadkkkkkkk=>>>quantityMultipleBy", quantityMultipleBy);
      const modifierArray =
        order?.modifiers?.length > 0
          ? order?.modifiers?.map((modifier) => {
              return {
                id: modifier?.modifier_id ?? "",
                modifier_id: modifier?.modifier_id ?? "",
                modifier_group_id: modifier?.modifier_group_id ?? "",
                action: modifier?.action ?? "",
                name: modifier?.name ?? "",
                unit_price: parseFloat(modifier?.modifier_price ?? 0),
                modifier_price: parseFloat(modifier?.modifier_price ?? 0),
                modifier_quantity: parseFloat(modifier?.modifier_quantity ?? 0),
              };
            })
          : [];

      // for discount modifier
      if (order?.discountModifierID !== "") {
        const modiItem = getItemById(
          order?.menu_item_id,
          order?.subCategoryid,
          table_detail
        );
        // console.log("modiItem", modiItem);
        if (modiItem !== null) {
          let disModifierIndex = -1;
          let disModifierItem = null;
          if (modiItem?.menu_item_type === "standard") {
            disModifierIndex = modiItem?.PosMenuitemsModifier?.findIndex(
              (p) => p.id == order?.discountModifierID
            );
            if (disModifierIndex > -1) {
              disModifierItem =
                modiItem?.PosMenuitemsModifier?.[disModifierIndex];
            }
          } else {
            disModifierIndex = modiItem?.DiscountModifiers?.findIndex(
              (p) => p.id == order?.discountModifierID
            );
            if (disModifierIndex > -1) {
              disModifierItem = modiItem?.DiscountModifiers?.[disModifierIndex];
            }
          }
          if (disModifierIndex > -1 && disModifierItem !== null) {
            modifierArray.push({
              id: disModifierItem?.id ?? "",
              modifier_id: disModifierItem?.id ?? "",
              modifier_group_id: disModifierItem?.modifier_group_id ?? "",
              action: disModifierItem?.action ?? "",
              name: disModifierItem?.modifier_name ?? "",
              unit_price: parseFloat(disModifierItem?.unit_price ?? 0),
              modifier_price: parseFloat(disModifierItem?.unit_price ?? 0),
              modifier_quantity: parseFloat(
                disModifierItem?.amount_per_item ?? 0
              ),
            });
          }
        }
      }
      // for discount modifier end

      const BundledMenuItemArray =
        order?.BundledMenuItem?.length > 0
          ? order?.BundledMenuItem?.map((bundledItem) => {
              const bundleModifiers =
                bundledItem?.bundledMenuitemModifers?.length > 0
                  ? bundledItem?.bundledMenuitemModifers.map(
                      (bundleModifier) => {
                        return {
                          id: bundleModifier?.id ?? "",
                          modifier_id: bundleModifier?.modifier_id ?? "",
                          modifier_group_id:
                            bundleModifier?.modifier_group_id ?? "",
                          action: bundleModifier?.action ?? "",
                          name: bundleModifier?.name ?? "",
                          unit_price: bundleModifier?.unit_price
                            ? parseFloat(bundleModifier?.unit_price)
                            : 0,
                          modifier_price: bundleModifier?.unit_price
                            ? parseFloat(bundleModifier?.modifier_price)
                            : 0,
                          modifier_quantity: bundleModifier?.unit_price
                            ? parseFloat(bundleModifier?.modifier_quantity)
                            : 0,
                          menu_item_id: bundleModifier?.menu_item_id ?? "",
                        };
                      }
                    )
                  : [];
              return {
                action_price: bundledItem?.action_price ?? "",
                amount: bundledItem?.amount
                  ? parseFloat(bundledItem?.amount)
                  : 0,
                amount_without_modifiers: bundledItem?.amount_without_modifiers
                  ? parseFloat(bundledItem?.amount_without_modifiers)
                  : 0,
                bundleName: bundledItem?.bundleName ?? "",
                bundle_menu_item_id: bundledItem?.bundle_menu_item_id
                  ? parseFloat(bundledItem?.bundle_menu_item_id)
                  : 0,
                bundle_menu_item_name: bundledItem?.bundle_menu_item_name ?? "",
                bundledMenuitemModifers: bundleModifiers,
                comment: bundledItem?.comment,
                id: bundledItem?.id ? parseFloat(bundledItem?.id) : 0,
                is_fired: bundledItem?.is_fired == 1 ? true : false,
                is_hold: bundledItem?.is_hold == 1 ? true : false,
                offlineID: bundledItem?.offlineID ?? "",
                option_series_id: bundledItem?.option_series_id
                  ? parseFloat(bundledItem?.option_series_id)
                  : 0,
                option_series_name: bundledItem?.option_series_name ?? "",
                quantity: bundledItem?.quantity
                  ? parseFloat(bundledItem?.quantity)
                  : 0,
                void: bundledItem?.void ? parseFloat(bundledItem?.void) : 0,
              };
            })
          : [];
      let item = {
        id: order?.menu_item_id,
        offline_id: order?.offlineID,
        name: order?.menu_item_name,
        image: order?.image ?? "",
        is_added: true,
        is_service_charge: order?.service_charge == 1 ? true : false,
        is_sales_tax: order?.sales_tax == 1 ? true : false,
        quantity: exactQyt,
        amount: menuItemPrice,
        amount_without_modifiers: parseFloat(menuPriceForOne),
        item_amount: menuItemPrice,
        comment: order?.comment,
        total: menuTotalPrice,
        actual_menu_price: parseFloat(order?.actual_menu_price ?? 0),
        menu_item_price: parseFloat(order?.menu_item_price ?? 0),
        is_fired: order?.is_fired == 1 ? true : false,
        is_hold: order?.is_hold == 1 ? true : false,
        menu_item_type: order?.menu_item_type ?? "",
        menu_price: order?.menu_price ?? "",
        service_charge_amount: order?.serviceChargeAmount
          ? parseFloat(order?.serviceChargeAmount)
          : 0,
        sales_tax_amount: order?.salesTaxAmount
          ? parseFloat(order?.salesTaxAmount)
          : 0,
        catID: order?.catID ?? "",
        sub_category_id: order?.subCategoryid ?? "",
        modifierArray:
          modifierArray?.length > 0
            ? modifierArray.sort(function (a, b) {
                return a.id - b.id;
              })
            : [],
        BundledMenuItem:
          BundledMenuItemArray?.length > 0
            ? BundledMenuItemArray.sort(function (a, b) {
                return a.id - b.id;
              })
            : [],
        selectedBundleItem:
          BundledMenuItemArray?.length > 0
            ? BundledMenuItemArray.sort(function (a, b) {
                return a.id - b.id;
              })
            : [],
      };
      items.push(item);
    });
  }
  return items;
}

export function getRandomString() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

export function getCartBody(
  recent_order_id,
  cart_detail,
  user_detail,
  table_detail,
  totalTip
) {
  const defaultServiceCharge =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_service_charge == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.service_charge_amount);
            }, 0)
        )
      : 0;
  const defaultTotalSaleTax =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_sales_tax == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.sales_tax_amount);
            }, 0)
        )
      : 0;
  const defaultTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  let items = [];
  const offlineId =
    recent_order_id !== undefined && recent_order_id !== ""
      ? recent_order_id
      : getRandomString();
  if (cart_detail && cart_detail.length > 0) {
    cart_detail.map((item) => {
      const modifierDetail = [];
      let discountModifierId = "";
      let discountModifierQty = null;
      if (item.modifierArray.length > 0) {
        item.modifierArray.map((modifier, index) => {
          if (modifier?.action == "discount") {
            discountModifierQty = modifier?.modifier_quantity ?? 1;
            discountModifierId = modifier?.modifier_id ?? "";
          } else {
            modifierDetail.push({
              name: modifier?.name ?? "",
              modifier_id: modifier?.modifier_id ?? "",
              modifier_group_id: modifier?.modifier_group_id ?? "",
              modifier_price: modifier?.modifier_price ?? 0,
              action: modifier?.action ?? "",
              // modifier_quantity: modifier?.modifier_quantity ?? 1,
              modifier_quantity: item?.quantity ?? 1,
            });
          }
        });
      }
      const menuItemPrice =
        discountModifierQty !== null
          ? item?.amount_without_modifiers / discountModifierQty
          : item?.amount_without_modifiers ?? 0;
      let itemDetail = {
        BundledMenuItem:
          item?.menu_item_type == "bundled" && item?.BundledMenuItem?.length > 0
            ? item?.BundledMenuItem
            : [],
        comment: item?.comment ?? "",
        image: item?.image ?? "",
        createDate: moment().format("YYYY/MM/DD hh:mm:ss"),
        discount: 0,
        doubleActualAmout: item?.total ?? 0,
        is_fired: item?.is_fired ?? false,
        is_hold: item?.is_hold ?? false,
        menu_item_id: item?.id ?? "",
        menu_item_name: item?.name ?? "",
        discountModifierID: discountModifierId,
        menu_item_price: menuItemPrice,
        menu_item_quantity:
          discountModifierQty !== null && item?.quantity
            ? discountModifierQty * item?.quantity
            : item?.quantity ?? 0,
        quantityMultipleBy:
          discountModifierQty !== null ? discountModifierQty : 1,
        menu_item_type: item?.menu_item_type ?? "",
        offlineID: item?.offline_id,
        promo141Quantity: 0,
        promotionTotaldiscount: 0,
        promotions: [],
        salesTaxAmount: item?.sales_tax_amount ?? 0,
        sales_tax: item?.is_sales_tax == true ? 1 : 0,
        serviceChargeAmount: item?.service_charge_amount ?? 0,
        service_charge: item?.is_service_charge == true ? 1 : 0,
        menu_price: item?.menu_price !== undefined ? item?.menu_price : "{}",
        subCategoryid: item?.sub_category_id ?? "",
        catID: item?.catID ?? "",
        void: 0,
        modifiers: modifierDetail?.length > 0 ? modifierDetail : [],
      };
      if (item?.menu_item_type == "bundled") {
        itemDetail.menu_item_price =
          discountModifierQty !== null
            ? item?.amount / discountModifierQty
            : item?.amount ?? 0;
        itemDetail.actual_menu_price =
          discountModifierQty !== null
            ? item?.actual_menu_price / discountModifierQty
            : item?.actual_menu_price ?? 0;
      }
      items.push(itemDetail);
    });
  }
  let body = {
    uuid: user_detail.device_id,
    qr_customer_id: user_detail.id,
    merchant_id: table_detail?.Merchant?.id,
    no_of_seats: table_detail?.PosTableMaster?.maximum_seat,
    order_offline_id: offlineId,
    order_time: moment().format("YYYY/MM/DD hh:mm:ss"),
    promotions: [],
    payment_type: [],
    order_status: "new",
    is_refund: false,
    service_charge: defaultServiceCharge,
    finishTime: null,
    refund: 0,
    paid_amount: 0,
    sales_tax: defaultTotalSaleTax,
    order_total: defaultTotalCartAmount,
    table_no: table_detail?.PosTableMaster?.table_number ?? "",
    channel_name: "Ordrr",
    finalTotalAmt:
      defaultTotalCartAmount + defaultTotalSaleTax + defaultServiceCharge,
    receiver: {
      customer_name: null,
      customer_phone: null,
      customer_email: user_detail?.email ?? null,
      customer_address: null,
    },
    menu_item: items,
  };
  return body;
}

export function parseStringToDate(
  date,
  from = "YYYY-MM-DD HH-mm-ss",
  to = "YYYY-MM-DD"
) {
  let res = undefined;
  try {
    if (date && typeof date === "string" && date !== "") {
      res = moment(date, from).format(to);
    }
  } catch (e) {}
  return res;
}

export function parseStringToFloat(num, to = 2) {
  let res = 0;
  try {
    if (num && num !== null) {
      const parseNum = parseFloat(num);
      if (parseNum) {
        res = parseNum.toFixed(to);
      }
    }
  } catch (e) {}
  return res;
}

export function groupByArray(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
}

export const imageExists = (url) =>
  new Promise(function (resolve, reject) {
    var img = new Image();
    img.src = url;

    img.onload = function () {
      resolve(url);
    };
    img.onerror = function () {
      reject(url);
    };
  });

export function checkItemAvailable(item, timezone = "00:00") {
  let res = false;
  const day_format = "YYYY-MM-DD";
  const day_currTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format(day_format);
  if (
    item?.servicePeriod?.[0]?.period_start !== "" &&
    item?.servicePeriod?.[0]?.period_end
  ) {
    const beforeDay = moment(
      item?.servicePeriod?.[0]?.period_start,
      day_format
    );
    const afterDay = moment(item?.servicePeriod?.[0]?.period_end, day_format);
    res = moment(day_currTime, day_format).isBetween(beforeDay, afterDay);
  }
  return res;

  const format = "HH:mm:ss";
  const currTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("HH:mm:ss");
  const currDay = moment(new Date()).utc().utcOffset(timezone).format("ddd");

  if (item?.applicablePeriod?.length > 0) {
    for (let index = 0; index < item?.applicablePeriod?.length; index++) {
      const key = Object.keys(item?.applicablePeriod[index])[0];
      const element = item?.applicablePeriod[index][key];

      const timeString = element?.time;
      const dayString = element?.availableday;
      const timeArr = timeString.split("-");
      const dayArr = dayString.split(",");

      if (
        timeArr.length > 1 &&
        dayArr.length > 0 &&
        dayArr.findIndex((p) => p.toLowerCase()) > -1
      ) {
        const beforeTime = moment(timeArr?.[0], format);
        const afterTime = moment(timeArr?.[1], format);
        if (moment(currTime, format).isBetween(beforeTime, afterTime)) {
          res = true;
          break;
        }
      }
    }
  }
  return res;
}

// Axios API call

// let getToken = async () => await AsyncStorage.getItem("access-token");
let getToken;

const client = axios.create({
  baseURL: "",
  // auth: { Authorization: "Bearer " + { getToken } },
});

export const apiCall = function (method, route, body = null, token = null) {
  getToken = token;
  const onSuccess = function (response) {
    console.debug("Request Successful!", response);
    return response.data;
  };

  const onError = function (error) {
    console.error("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  let reqObj = {
    method,
    url: `${route}`,
    data: body,
  };
  if (token) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    client.defaults.headers.common["Content-Type"] = `application/json`;
    client.defaults.headers.common[
      "SoapAction"
    ] = `https://matrixapis/JSONCommand2`;
    console.log("common-headers", client.defaults.headers.common);

    // client.defaults.headers.common["origin"] = `http://localhost:3000`;
    // client.defaults.headers.common[
    //   "Content-Type"
    // ] = `application/x-www-form-urlencoded`;
    // client.defaults.proxy.host = "http://www.demoapp.com";
    // reqObj.headers = {
    //   // "Content-Type": `application/json`,
    //   // Accept: `application/json`,
    //   Authorization: `Bearer ${token}`,
    //   // SoapAction: "https://matrixapis/JSONCommand2",
    //   // "Access-Control-Allow-Credentials": "true",
    //   // "Access-Control-Allow-Methods":
    //   //   "GET, POST, PATCH, PUT, DELETE, OPTIONS, HEAD",
    //   // "Access-Control-Allow-Origin": "*",
    //   // "Access-Control-Allow-Headers":
    //   //   "Origin, X-Requested-With, Content-Type, Accept",
    // };
  }
  console.log("reqObj", reqObj);

  return client(reqObj).then(onSuccess).catch(onError);
};
