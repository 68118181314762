import { toast } from "react-toastify";
import {
  LOADING_STATUS,
  GET_PDF_MENU,
  EMPTY_TOAST_MSG,
  TOAST_MSG,
  HIDE_TOAST_MSG,
  SHOW_NOTIFICATION_WITH_TIMEOUT,
  GET_TABLE_DETAIL,
  EMPTY_PDF_MENU,
  EMPTY_TABLE_DETAIL,
  ADD_FILTER_TAG,
  GET_SELECTED_ITEM_DETAIL,
  SEND_OTP_DETAIL,
  EMPTY_OTP_DETAIL,
  ADD_ITEM_CART_DETAIL,
  UPDATE_ITEM_CART_DETAIL,
  REMOVE_ITEM_CART_DETAIL,
  EMPTY_CART_DETAIL,
  ADD_SEARCH_TAG_RECEIVED,
  GET_USER_DETAIL,
  GET_LOYALITY_DETAIL,
  EMPTY_USER_DETAIL,
  EMAIL_INPUT_RECEIVED,
  OTP_INPUT_RECEIVED,
  ADD_SEARCH_TEXT,
  CART_DETAIL_RECEIVED,
  ORDER_PLACE_DETAIL,
  CART_TAX_DETAIL_RECEIVED,
  EMPTY_CART_TAX_DETAIL,
  CHANGE_TABLE_NOT_FOUND_MODAL,
  CHANGE_TABLE_ALREADY_INUSE_MODAL,
  CHANGE_ORDER_SOCKET_EMIT,
  GET_ORDER_HISTORY_DETAIL,
  EDITED_ITEM_DETAIL_RECEIVED,
  EMPTY_EDITED_ITEM_DETAIL,
  EMPTY_ORDER_PLACE_DETAIL,
  DELETE_RECENT_AND_CART_ORDER,
} from "./types";

// Loading
export const changeLoadingStatus = (status) => ({
  type: LOADING_STATUS,
  payload: status,
});

// Cart Detail
export const addItemCartDetail = (item) => ({
  type: ADD_ITEM_CART_DETAIL,
  item,
});
export const updateItemCartDetail = (index, item) => ({
  type: UPDATE_ITEM_CART_DETAIL,
  index,
  item,
});
export const removeItemCartDetail = (index) => ({
  type: REMOVE_ITEM_CART_DETAIL,
  index,
});

export const changeStatusCartDetail = (cartDetail) => ({
  type: CART_DETAIL_RECEIVED,
  cart_detail: cartDetail,
});

export const emptyCartDetail = () => ({
  type: EMPTY_CART_DETAIL,
});

export const emptyOrderPlaceDetail = () => ({
  type: EMPTY_ORDER_PLACE_DETAIL,
});
export const deletRecentAndCartOrder = () => ({
  type: DELETE_RECENT_AND_CART_ORDER,
});

export const saveCartTaxDetail = (
  totalCartAmount,
  totalServiceCharge,
  totalSaleTax,
  totalDiscount,
  totalTip
) => ({
  type: CART_TAX_DETAIL_RECEIVED,
  total_cart_amount: totalCartAmount,
  total_service_charge: totalServiceCharge,
  total_sale_tax: totalSaleTax,
  total_discount: totalDiscount,
  total_tip: totalTip,
});

export const emptyCartTaxDetail = () => ({
  type: EMPTY_CART_TAX_DETAIL,
});

export const orderPlaceDetail = (body) => ({
  type: ORDER_PLACE_DETAIL,
  body: body,
});

export const changeOrderSocketEmit = (status) => ({
  type: CHANGE_ORDER_SOCKET_EMIT,
  payload: status,
});

// OTP verification
export const sendOtpDetail = (email, otp, merchantId, deviceId) => ({
  type: SEND_OTP_DETAIL,
  email,
  otp,
  merchantId,
  deviceId,
});

export const emptyOtpDetail = () => ({
  type: EMPTY_OTP_DETAIL,
});

// Table Detail

export const getTableDetail = (id, deviceId) => ({
  type: GET_TABLE_DETAIL,
  id,
  deviceId,
});

export const emptyTableDetail = () => ({
  type: EMPTY_TABLE_DETAIL,
});

export const getTableDetailByFilter = (table_id, ids, input) => ({
  type: ADD_FILTER_TAG,
  table_id,
  ids,
  input,
});

export const updateSearchInput = (input) => ({
  type: ADD_SEARCH_TAG_RECEIVED,
  input,
});

export const updateSearchText = (input) => ({
  type: ADD_SEARCH_TEXT,
  input,
});

export const getSelectedItemDetail = (id) => ({
  type: GET_SELECTED_ITEM_DETAIL,
  id,
});

export const getEditedItemDetail = (item) => ({
  type: EDITED_ITEM_DETAIL_RECEIVED,
  payload: item,
});

export const emptyEditedItemDetail = (item) => ({
  type: EMPTY_EDITED_ITEM_DETAIL,
});

export const emptyChangeTableNotFound = () => ({
  type: CHANGE_TABLE_NOT_FOUND_MODAL,
  payload: false,
});
export const emptyChangeTableAlreadyInuse = () => ({
  type: CHANGE_TABLE_ALREADY_INUSE_MODAL,
  payload: false,
});
export const changeTableNotFound = () => ({
  type: CHANGE_TABLE_NOT_FOUND_MODAL,
  payload: true,
});

// user detail

export const getUserDetail = (email, merchantId, deviceId, tableNumber) => {
  return { type: GET_USER_DETAIL, email, merchantId, deviceId, tableNumber };
};

export const emptyUserDetail = () => ({
  type: EMPTY_USER_DETAIL,
});

export const updateEmailInput = (input) => {
  return { type: EMAIL_INPUT_RECEIVED, input };
};
export const updateOtpInput = (input) => {
  return { type: OTP_INPUT_RECEIVED, input };
};

// loyality detail

export const getLoyalityDetail = (
  card,
  cvv,
  merchantId,
  deviceId,
  tableNumber
) => {
  return {
    type: GET_LOYALITY_DETAIL,
    card,
    cvv,
    merchantId,
    deviceId,
    tableNumber,
  };
};

// order history
export const getOrderHistoryDetail = (card, merchantId, deviceId) => ({
  type: GET_ORDER_HISTORY_DETAIL,
  card,
  merchantId,
  deviceId,
});

// pdf menu

export const getPdfMenu = (id) => {
  return { type: GET_PDF_MENU, id };
};

export const emptyPdfMenu = () => ({
  type: EMPTY_PDF_MENU,
});

// Notifications
export const emptyToastMsg = () => ({
  type: EMPTY_TOAST_MSG,
});

export const showNotification = (id, text, msgtype) => {
  return { type: TOAST_MSG, id, text, msgtype };
};

export const hideNotification = (id) => {
  return { type: HIDE_TOAST_MSG, id };
};

export const showNotificationWithTimeout = (text, msgtype) => {
  if (msgtype == "success") {
    toast.success(text);
  } else if (msgtype == "error") {
    toast.error(text);
  } else {
    toast(text);
  }
  return { type: SHOW_NOTIFICATION_WITH_TIMEOUT, text, msgtype };
};
