export const LOADING_STATUS = "LOADING_STATUS";
// USER_DETAIL
export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const EMPTY_USER_DETAIL = "EMPTY_USER_DETAIL";
export const USER_DETAIL_RECEIVED = "USER_DETAIL_RECEIVED";
export const EMAIL_INPUT_RECEIVED = "EMAIL_INPUT_RECEIVED";
export const OTP_INPUT_RECEIVED = "OTP_INPUT_RECEIVED";
export const SHOW_OTP_INPUT_TOGGLE = "SHOW_OTP_INPUT_TOGGLE";
// PDF_MENU
export const GET_PDF_MENU = "GET_PDF_MENU";
export const EMPTY_PDF_MENU = "EMPTY_PDF_MENU";
export const PDF_MENU_RECEIVED = "PDF_MENU_RECEIVED";
// TOAST_MSG
export const EMPTY_TOAST_MSG = "EMPTY_TOAST_MSG";
export const TOAST_MSG = "TOAST_MSG";
export const HIDE_TOAST_MSG = "HIDE_TOAST_MSG";
export const SHOW_NOTIFICATION_WITH_TIMEOUT = "SHOW_NOTIFICATION_WITH_TIMEOUT";
// TABLE DETAIL
export const GET_TABLE_DETAIL = "GET_TABLE_DETAIL";
export const TABLE_DETAIL_RECEIVED = "TABLE_DETAIL_RECEIVED";
export const EMPTY_TABLE_DETAIL = "EMPTY_TABLE_DETAIL";
export const FILTER_TAG_DETAIL_RECEIVED = "FILTER_TAG_DETAIL_RECEIVED";
export const ADD_FILTER_TAG = "ADD_FILTER_TAG";
export const ADD_FILTER_TAG_RECEIVED = "ADD_FILTER_TAG_RECEIVED";
export const ADD_SEARCH_TAG_RECEIVED = "ADD_SEARCH_TAG_RECEIVED";
export const ADD_SEARCH_TEXT = "ADD_SEARCH_TEXT";
export const GET_SELECTED_ITEM_DETAIL = "GET_SELECTED_ITEM_DETAIL";
export const SELECTED_ITEM_DETAIL_RECEIVED = "SELECTED_ITEM_DETAIL_RECEIVED";
export const CHANGE_TABLE_NOT_FOUND_MODAL = "CHANGE_TABLE_NOT_FOUND_MODAL";
export const CHANGE_TABLE_ALREADY_INUSE_MODAL =
  "CHANGE_TABLE_ALREADY_INUSE_MODAL";
export const EDITED_ITEM_DETAIL_RECEIVED = "EDITED_ITEM_DETAIL_RECEIVED";
export const EMPTY_EDITED_ITEM_DETAIL = "EMPTY_EDITED_ITEM_DETAIL";
// OTP DETAIL
export const SEND_OTP_DETAIL = "SEND_OTP_DETAIL";
export const SEND_OTP_DETAIL_RECEIVED = "SEND_OTP_DETAIL_RECEIVED";
export const EMPTY_OTP_DETAIL = "EMPTY_OTP_DETAIL";
// Cart DETAIL
export const ADD_ITEM_CART_DETAIL = "ADD_ITEM_CART_DETAIL";
export const UPDATE_ITEM_CART_DETAIL = "UPDATE_ITEM_CART_DETAIL";
export const REMOVE_ITEM_CART_DETAIL = "REMOVE_ITEM_CART_DETAIL";
export const CART_DETAIL_RECEIVED = "CART_DETAIL_RECEIVED";
export const EMPTY_CART_DETAIL = "EMPTY_CART_DETAIL";
export const CART_TAX_DETAIL_RECEIVED = "CART_TAX_DETAIL_RECEIVED";
export const EMPTY_CART_TAX_DETAIL = "EMPTY_CART_TAX_DETAIL";
export const CART_DETAIL_STATUS_CHANGED = "CART_DETAIL_STATUS_CHANGED";
export const CHANGE_ORDER_SOCKET_EMIT = "CHANGE_ORDER_SOCKET_EMIT";
export const DELETE_RECENT_AND_CART_ORDER = "DELETE_RECENT_AND_CART_ORDER";
// Order DETAIL
export const ORDER_PLACE_DETAIL = "ORDER_PLACE_DETAIL";
export const ORDER_PLACE_DETAIL_RECEIVED = "ORDER_PLACE_DETAIL_RECEIVED";
export const EMPTY_ORDER_PLACE_DETAIL = "EMPTY_ORDER_PLACE_DETAIL";
// Loyality
export const GET_LOYALITY_DETAIL = "GET_LOYALITY_DETAIL";
export const CARD_DETAIL_RECEIVED = "CARD_DETAIL_RECEIVED";
export const EMPTY_CARD_DETAIL = "EMPTY_CARD_DETAIL";
export const MEMBER_DETAIL_RECEIVED = "MEMBER_DETAIL_RECEIVED";
export const EMPTY_MEMBER_DETAIL = "EMPTY_MEMBER_DETAIL";
// Order History
export const GET_ORDER_HISTORY_DETAIL = "GET_ORDER_HISTORY_DETAIL";
export const ORDER_HISTORY_DETAIL_RECEIVED = "ORDER_HISTORY_DETAIL_RECEIVED";
export const EMPTY_ORDER_HISTORY_DETAIL = "EMPTY_ORDER_HISTORY_DETAIL";
// ORDER ID
export const RECENT_ORDER_ID_RECEIVED = "RECENT_ORDER_ID_RECEIVED";
export const EMPTY_RECENT_ORDER_ID = "EMPTY_RECENT_ORDER_ID";
// Voucher List
export const GET_VOUCHER_LIST_DETAIL = "GET_VOUCHER_LIST_DETAIL";
export const VOUCHER_LIST_DETAIL_RECEIVED = "VOUCHER_LIST_DETAIL_RECEIVED";
export const EMPTY_VOUCHER_LIST_DETAIL = "EMPTY_VOUCHER_LIST_DETAIL";
