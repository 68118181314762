import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Motion, spring, presets } from "react-motion";
import { useSwipeable, config } from "react-swipeable";
import DraggableItem from "../Components/DraggableItem";
import {} from "../../Assets";
import Header from "../Components/Header";
import CartHeader from "../Components/CartHeader";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";
import AccountPopup from "../Components/AccountPopup";
import {
  showNotificationWithTimeout,
  changeStatusCartDetail,
  saveCartTaxDetail,
  orderPlaceDetail,
  removeItemCartDetail,
  getSelectedItemDetail,
} from "../../Redux/actions";
import { getCartBody } from "../../helpers";

function Cart(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );
  const cart_detail = useSelector(
    (state) => state.cartDetailReducer?.cart_detail
  );
  // console.log("cart_detail", JSON.stringify(cart_detail));
  const recent_order_detail = useSelector(
    (state) => state.cartDetailReducer?.recent_order_detail
  );
  const recent_order_id = useSelector(
    (state) => state.cartDetailReducer?.recent_order_id
  );
  const user_detail = useSelector(
    (state) => state.authDetailReducer?.user_detail
  );
  const total_tip = useSelector((state) => state.cartDetailReducer?.total_tip);
  const defaultTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  const serviceChargePer = parseFloat(table_detail?.ServiceCharges ?? 0);
  const salesTaxPer = parseFloat(table_detail?.SalesTaxRate ?? 0);
  const defaultServiceCharge =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_service_charge == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.service_charge_amount);
            }, 0)
        )
      : 0;
  const defaultTotalSaleTax =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_sales_tax == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.sales_tax_amount);
            }, 0)
        )
      : 0;

  const [showAccountPopUp, setShowAccountPopUp] = useState(0);
  const [accountTabType, setAccountTabType] = useState(0);
  const [isCartChanged, setIsCartChanged] = useState(false);
  const [totalCartAmount, setTotalCartAmount] = useState(
    defaultTotalCartAmount
  );
  const [totalServiceCharge, setTotalServiceCharge] = useState(
    defaultServiceCharge
  );
  const [totalSaleTax, setTotalSaleTax] = useState(defaultTotalSaleTax);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalTip, setTotalTip] = useState("$0.00");
  const [hideHeader, setHideHeader] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        isHeaderAllUp === true
      ) {
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setHideHeader(true);
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  useEffect(() => {
    const newTotalCartAmount =
      cart_detail && cart_detail.length > 0
        ? cart_detail.reduce(function (sum, current) {
            return sum + parseFloat(current.total);
          }, 0)
        : 0;
    const newServiceCharge =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_service_charge == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.service_charge_amount);
              }, 0)
          )
        : 0;
    const newTotalSaleTax =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_sales_tax == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.sales_tax_amount);
              }, 0)
          )
        : 0;
    setTotalSaleTax(newTotalSaleTax);
    setTotalServiceCharge(newServiceCharge);
    setTotalCartAmount(newTotalCartAmount);
  }, [showAccountPopUp, cart_detail]);
  function removeItemHandler(index) {
    const newCartDetail = cart_detail.filter((item, i) => i !== index);
    setIsCartChanged(true);
    let body = getCartBody(
      recent_order_id,
      newCartDetail,
      user_detail,
      table_detail,
      totalTip
    );
    orderPlaceDetail(body);
    dispatch(removeItemCartDetail(index));
  }

  function getCartItems() {
    if (cart_detail && cart_detail.length > 0) {
      return cart_detail.map(function (item, itemIndex) {
        return (
          <DraggableItem
            item={item}
            itemIndex={itemIndex}
            defaultimage={table_detail?.Merchant?.logo}
            removeItemHandler={removeItemHandler}
            key={itemIndex}
          />
        );
      });
    }
    return null;
  }

  function getPopularItems() {
    let res = [];
    if (table_detail?.SubCategory?.length > 0) {
      table_detail?.SubCategory.filter(
        (subCat) => subCat?.MenuItem.findIndex((p) => p.is_popular == 1) > -1
      ).map(function (subCat, index) {
        if (subCat?.MenuItem) {
          subCat?.MenuItem.filter((item) => item.is_popular == 1).map(function (
            item,
            itemKey
          ) {
            res.push(item);
          });
        }
      });
    }
    if (res.length > 0) {
      return res.slice(0, 4).map((item) => {
        return (
          <div
            className="col-6"
            onClick={() => {
              dispatch(getSelectedItemDetail(item));
              history.push("/add-item");
            }}
          >
            <figure className="menu-img mx-auto mb-3">
              <ImageTag
                src={item?.image}
                defaultimage={table_detail?.Merchant?.logo}
                removeImage={true}
                alt=""
              />
            </figure>
            <h5 className="sm-h5"> {item?.pos_name ?? ""} </h5>
            <p>
              {" "}
              @
              {item?.dinein_price?.DineIn !== undefined
                ? parseFloat(item?.dinein_price?.DineIn).toFixed(3).slice(0, -1)
                : "0.00"}
            </p>
          </div>
        );
      });
    } else {
      return null;
    }
  }

  function getBasketTotalAmount() {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      res =
        parseFloat(totalCartAmount.toFixed(3).slice(0, -1)) +
        parseFloat(totalServiceCharge.toFixed(3).slice(0, -1)) +
        parseFloat(
          parseFloat(totalTip.toString().replace("$", ""))
            .toFixed(3)
            .slice(0, -1)
        ) +
        parseFloat(totalSaleTax.toFixed(3).slice(0, -1)) -
        parseFloat(totalDiscount.toFixed(3).slice(0, -1));
    }
    return (
      <>
        <small>{`${table_detail?.currency ?? ""}`}</small>
        {parseFloat(res).toFixed(3).slice(0, -1)}
      </>
    );
  }

  function tipChangeHandler(el) {
    let res = 0;
    if (
      el?.target?.value !== undefined &&
      !isNaN(parseFloat(el?.target?.value)) &&
      isFinite(el?.target?.value)
    ) {
      console.log(el?.target?.value);
      const inputVal =
        parseFloat(el?.target?.value) > 1000000 ? 1000000 : el?.target?.value;
      res = parseFloat(inputVal).toFixed(3).slice(0, -1);
    } else if (el?.target?.value !== "") {
      dispatch(
        showNotificationWithTimeout("Please enter valid number!", "error")
      );
    }
    setTotalTip(parseFloat(res));
  }

  function getBasketTotalQuantity() {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      res = cart_detail.reduce(function (sum, current) {
        return sum + parseInt(current.quantity);
      }, 0);
    }
    return <div className="items-count"> {res} </div>;
  }

  function orderPlaceHandler() {
    let body = getCartBody(
      recent_order_id,
      cart_detail,
      user_detail,
      table_detail,
      totalTip
    );
    dispatch(orderPlaceDetail(body));
    // dispatch(
    //   saveCartTaxDetail(
    //     totalCartAmount,
    //     totalServiceCharge,
    //     totalSaleTax,
    //     totalDiscount,
    //     parseFloat(
    //       parseFloat(totalTip.toString().replace("$", ""))
    //         .toFixed(3)
    //         .slice(0, -1)
    //     )
    //   )
    // );
    history.push("/new-items");
  }

  function getCartDetail() {
    // console.log("defaultTotalCartAmount", defaultTotalCartAmount);
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <div
          className="wrapper cart_wrapper custom_pb cart_sidebar"
          onScroll={scrolledElement}
          {...handlers}
          style={props.style}
        >
          <Motion
            defaultStyle={{ x: 270 }}
            style={{
              x: spring(hideHeader === false ? 159 : 0, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => (
              <CartHeader
                backTitle={"Back to Order"}
                onTabProfile={() => setShowAccountPopUp(1)}
                backPath={() => {
                  props.setShowCartPage();
                  // setHideHeader(true);
                }}
                style={{
                  height: `${x}px`,
                }}
                rtComponent={
                  <>
                    <div className="hotel_name">
                      <div className="circle-img">
                        <figure className="">
                          <ImageTag src={table_detail?.Merchant?.logo} alt="" />
                        </figure>
                      </div>
                      <div className="naming-sec text-center">
                        <h4> {table_detail?.Merchant?.account_name} </h4>
                        <p>
                          {" "}
                          Table #{
                            table_detail?.PosTableMaster?.table_number
                          }{" "}
                          Basket{" "}
                        </p>
                      </div>
                      <div className="col-12 py-3 px-0">
                        <h5 className="green-title"> Submitted Items </h5>
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </Motion>
          <Motion
            defaultStyle={{ x: 270 }}
            style={{
              x: spring(hideHeader === false ? 0 : 70, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => (
              <CartHeader
                backTitle={"Back to Order"}
                onTabProfile={() => setShowAccountPopUp(1)}
                backPath={() => {
                  props.setShowCartPage();
                  // setHideHeader(true);
                }}
                style={{
                  height: `${x}px`,
                  position: "fixed",
                  top: "0px",
                  width: "100%",
                  zIndex: 99,
                  right: `${props.style.right}`,
                  backgroundColor: "#f5f6f7",
                }}
                rtComponent={<></>}
              />
            )}
          </Motion>
          {/* 
                    <header>
                        <div className="container">
                            <div className="row mx-0">
                                <div className="col-8">
                                    <a href="index.html" className="back"> Back to Order </a>
                                </div>
                                <div className="col-4 notify">
                                    <a href="#_" className="notification">
                                        <span className="counts"> 10 </span>
                                    </a>
                                    <a href="#_" className="profile"> </a>
                                </div>
                            </div>
        
        
        
                            <div className="row hotel_name">
                                <div className="circle-img col-2">
                                    <figure className=""><img src={userImgIcon} alt="" /></figure>
                                </div>
                                <div className="naming-sec col-6 px-0 text-center">
                                    <h4> Harry's Boat Quay </h4>
                                    <p> Table #17 Basket </p>
                                </div>
                            </div>
                            <div className="col-12 py-3">
                                <h5 className="green-title"> View Submitted Items </h5>
                            </div>
                        </div>
                <div className="items-count"> 2 </div>
                    </header> */}

          <div className={`outerScroll ${hideHeader ? "scrollup" : ""}`}>
            <section className="middle-sec cart-sec-ms new_items_page">
              {getCartItems()}
            </section>

            {table_detail?.SubCategory &&
              table_detail.SubCategory.findIndex(
                (subCat) =>
                  subCat?.MenuItem.findIndex((p) => p.is_popular == 1) > -1
              ) > -1 && (
                <section className="recommended">
                  <h3 className="title"> You Might Also Like </h3>
                  <div className="row also-like-sec">{getPopularItems()}</div>
                </section>
              )}

            <section className="billing-details mb-5">
              <ul className="billing-list">
                <li>
                  {" "}
                  <span> Discounts </span>{" "}
                  <strong>
                    {" "}
                    (<small>{`${table_detail?.currency ?? ""}`}</small>0.00)
                  </strong>
                </li>
                <li>
                  {" "}
                  <span> Sub Total </span>{" "}
                  <strong>
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {`${parseFloat(totalCartAmount).toFixed(3).slice(0, -1)}`}
                  </strong>
                </li>
                <li>
                  {" "}
                  <span> Service Charges </span>{" "}
                  <strong>
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {parseFloat(totalServiceCharge).toFixed(3).slice(0, -1)}
                  </strong>
                </li>

                <li>
                  {" "}
                  <span> Tax </span>{" "}
                  <strong>
                    {" "}
                    <small>{`${table_detail?.currency ?? ""}`}</small>
                    {parseFloat(totalSaleTax).toFixed(3).slice(0, -1)}
                  </strong>
                </li>
                <li>
                  {" "}
                  <span> Gratuity </span>{" "}
                  <input
                    type="text"
                    className="type-text"
                    value={totalTip}
                    onFocus={() =>
                      setTotalTip(totalTip.toString().replace("$", ""))
                    }
                    onBlur={() => setTotalTip("$" + totalTip.toString())}
                    onChange={(el) => tipChangeHandler(el)}
                  />
                </li>
                <li className="total">
                  {" "}
                  <span> Total </span>{" "}
                  <strong> {getBasketTotalAmount()}</strong>
                </li>
              </ul>
              <div className="row mx-0 justify-content-between px-4">
                <div
                  onClick={() => {
                    setAccountTabType(1);
                    setShowAccountPopUp(1);
                  }}
                  className="link"
                >
                  {" "}
                  Use Promo Code{" "}
                </div>
                <div
                  onClick={() => {
                    setAccountTabType(1);
                    setShowAccountPopUp(1);
                  }}
                  className="link"
                >
                  {" "}
                  Use Voucher{" "}
                </div>
              </div>
            </section>
          </div>

          <section
            className={`bottom-sec ${
              cart_detail?.filter((p) => p?.is_added == false)?.length <= 0
                ? "disabled"
                : ""
            }`}
            style={props.style}
          >
            <div
              onClick={() => {
                if (
                  cart_detail?.filter((p) => p?.is_added == false)?.length > 0
                ) {
                  orderPlaceHandler();
                }
              }}
              className="green-sec py-3"
            >
              <div className="col-2">{getBasketTotalQuantity()}</div>
              <div className="col-8 text-center">
                <strong> Submit New Items </strong>
              </div>
              <div className="col-2"></div>
            </div>
          </section>
          {/* account modal */}

          {/* {(showAccountPopUp === 0 || showAccountPopUp === 1) && ( */}
          <AccountPopup
            show={showAccountPopUp}
            tabType={accountTabType}
            onCancel={() => {
              setShowAccountPopUp(0);
              setAccountTabType(0);
            }}
          />
          {/* )} */}
        </div>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getCartDetail()}</>;
}

export default Cart;
