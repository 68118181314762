import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Collapsible from "react-collapsible";

import { getTypeOfVoucher } from "../../helpers";
import { voucherPngIcon } from "../../Assets";
import { VoucherTabbar } from "../Components/Tabbar";
import PromotionDetails from "../Components/PromotionDetails";

function Vouchers(params) {
  const cardDetail = useSelector(
    (state) => state?.authDetailReducer?.card_detail
  );
  const voucherListDetail = useSelector(
    (state) => state?.cartDetailReducer?.voucher_list_detail
  );
  const [activeTab, setActiveTab] = useState(0);

  const switchTab = useCallback(
    (value) => {
      setActiveTab(value);
    },
    [activeTab]
  );

  const recordNotFound = (
    <div className="promotion_details text-center">
      <p>no records found</p>
    </div>
  );

  function getProtionItems() {
    let res = recordNotFound;
    if (voucherListDetail !== null) {
      if (activeTab === 0 && voucherListDetail?.ActiveVoucherLists !== null) {
        res = voucherListDetail?.ActiveVoucherLists.map((voucher, index) => (
          <PromotionDetails voucher={voucher} key={index} />
        ));
      } else if (
        activeTab === 1 &&
        voucherListDetail?.RedeemedVoucherLists !== null
      ) {
        res = voucherListDetail?.RedeemedVoucherLists.map((voucher, index) => (
          <PromotionDetails voucher={voucher} key={index} />
        ));
      } else if (
        activeTab === 2 &&
        voucherListDetail?.ExpiredVoucherLists !== null
      ) {
        res = voucherListDetail?.ExpiredVoucherLists.map((voucher, index) => (
          <PromotionDetails voucher={voucher} key={index} />
        ));
      }
    }
    return res;
  }

  return (
    <section className="info-sec">
      <div className="history-info" id="vouchers">
        <h4 className="title"> Vouchers </h4>
        <VoucherTabbar activeTab={activeTab} switchTab={switchTab} />
        {getProtionItems()}
        {/* {cardDetail?.VoucherLists?.filter((p) =>
          getTypeOfVoucher(activeTab, p)
        )?.map((voucher, index) => (
          <PromotionDetails voucher={voucher} key={index} />
        ))}
        {(cardDetail?.VoucherLists == undefined ||
          cardDetail?.VoucherLists?.filter((p) =>
            getTypeOfVoucher(activeTab, p)
          )?.length <= 0) &&
          recordNotFound} */}
      </div>
    </section>
  );
}

export default Vouchers;
